.container {
  width: 30vw;
  padding: 2%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffff;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(180, 196, 235, 0.25);
  border-radius: 20px;
  outline: 3px #d6e1fe solid;
  margin-top: 6%;
  // position: relative;
  .cancelIconDiv {
    justify-content: flex-end;
    align-items: center;
    height: 1;
    width: 100%;
    .cancelIcon {
      font-size: 2rem;
      color: #98afff;
    }
  }
  h1 {
    color: #0d30ac;
    font-size: 2rem;
    font-family: Raleway;
    font-weight: 600;
    word-wrap: break-word;
    margin-bottom: 5%;
  }

  h2 {
    color: #0d30ac;
    font-size: 1.4rem;
    font-family: Raleway;
    font-weight: 600;
    word-wrap: break-word;
  }

  .inputBox {
    margin-top: 1%;
    margin-bottom: 5%;
    width: 24vw;
    padding: 0 3%;
    box-shadow: 0px 1px 3px 0.3px lightgray;
    display: flex;
    align-items: center;
    position: relative;
    background: #f8f8f8;
    border-radius: 8px;
    .SecurityIcon {
      width: 25px;
      height: 25px;
    }
    .EyeIcon {
      height: 42px;
      width: 25px;
      color: rgb(107, 106, 106);
    }

    input {
      width: 100%;
      background: transparent;
      height: 50px;
      border: none;
      box-shadow: none;
      padding-left: 4%;
      font-size: 1rem;
    }
  }

  .submitBtn {
    margin-top: 4%;
    width: 10vw;
    height: 7vh;
    margin-top: 2%;
    border-radius: 8px;
    color: white;
    background: #0d30ac;
    font-size: 1.1rem;
    font-family: Raleway;
    font-weight: 600;
  }

  .fpLink {
    margin-top: 2%;
    a {
      width: 8vw;
      height: 3vh;
      text-align: center;
      color: #0d30ac;
      font-size: 1rem;
      font-family: Raleway;
      font-weight: 600;
      text-decoration: underline;
      word-wrap: break-word;
    }
  }
  .Popup {
    width: 20vw;
    height: 10vh;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    border-radius: 5px;
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 6;
  }

  .Epopup {
    background-color: rgb(255, 78, 38);
  }
  .Spopup {
    background-color: rgb(16, 183, 58);
  }
  .Popup p {
    color: white;
  }
  @media only screen and (max-width: 1024px) {
    width: 50vw;
    height: auto;
    padding: 3%;
    margin: auto;
    margin-top: 20%;
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.4rem;
      margin-top: 0;
    }

    .inputBox {
      width: 40vw;

      .SecurityIcon {
        top: 15px;
        left: 4%;
        width: 22px;
      }

      input {
        padding-left: 16%;
        font-size: 1rem;
        margin-bottom: 4%;
      }
    }

    .submitBtn {
      margin-top: 4%;
      width: 22vw;
      height: 4vh;
      margin-top: 2%;
      font-size: 1rem;
    }

    .fpLink {
      margin-top: 1%;
      a {
        font-size: 01rem;
      }
    }
    .Popup {
      width: 40vw;
      height: 8vh;
    }
  }
  @media only screen and (max-width: 820px) {
    width: 60vw;
    .inputBox {
      width: 50vw;
      height: 4.4vh;
    }
  }
  @media only screen and (max-width: 450px) {
    width: 90vw;
    margin-top: 8%;
    h1 {
      font-size: 1.6rem;
      margin-bottom: 1%;
    }

    h2 {
      font-size: 1.2rem;
      margin-top: 4%;
    }

    .inputBox {
      margin-top: 1%;
      width: 80vw;

      .SecurityIcon {
        top: 15px;
        left: 4%;
        width: 22px;
      }

      input {
        padding-left: 16%;
        font-size: 1rem;
      }
    }

    .submitBtn {
      margin-top: 4%;
      width: 26vw;
      height: 5vh;
      margin-top: 2%;
      font-size: 1rem;
    }

    .fpLink {
      margin-top: 0;
      a {
        width: 8vw;
        height: 3vh;
        font-size: 0.8rem;
      }
    }
    .Popup {
      width: 70vw;
    }
  }
}
