.root {
  min-height: 79vh;
  padding: 1% 3%;
  border-radius: 16px;
  border: 3px solid #4370cb3c;
  .header {
    width: 100%;
    margin: 1% auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .headerContainer {
      display: flex;
      align-items: center;
      width: max-content;
      gap: 20px;

      .userImage {
        width: 4vw;
        height: 4vw;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          border: 1px solid #0057b389;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .userName {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-weight: 500;
          font-size: 1.8rem;
          color: #0d1dac;
        }
      }
    }

    .headerButtons {
      width: 12vw;
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      font-weight: 500;
      .button {
        padding: 4px 20px;
        border: 1px solid #0d30ac;
        border-radius: 10px;
        color: #0d1dac;
      }
      .buttonChat {
        color: #ffffff;
        padding: 4px 20px;
        background-color: #0d30ac;
        border-radius: 10px;
        border: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .graph {
    .textContainer {
      justify-content: flex-end;

      .graphSelector {
        display: flex;
        border-radius: 20px;
        background-color: #f0f5ff;
        color: #0d30ac;
        padding: 0 2%;
        width: 11vw;
        border: 2px solid #4370cb3c;
        &:hover {
          background-color: #e5edfe;
        }
        .selector {
          flex: 1;
          height: 5vh;
          border: none;
          background-color: transparent;
          outline: none;
          font-weight: 600;
          position: relative;
          cursor: pointer;
        }
      }
    }
    .chartContainer {
      align-items: center;
      margin-bottom: 3%;
      div {
        .text {
          font-size: 1.4rem;
          font-weight: 600;
          color: #0d30ac;
          margin-bottom: 2%;
        }
        .chart {
          width: 50vw;
          height: 52vh;
          padding: 3%;
          border-radius: 10px;
          background: linear-gradient(
            180deg,
            rgba(221, 221, 221, 0.12) 0%,
            rgba(221, 221, 221, 0.066) 100%
          );
        }
      }
      .values {
        width: 20vw;
        height: 100%;

        .valueCard {
          background-color: #f5f5f5;
          .head {
            font-size: 1rem;
            font-weight: 600;
            // line-height: 10px;
          }
          padding: 3% 6%;
          border-radius: 8px;

          box-shadow: 0px 0.5px 0.5px 1px lightgray;
          line-height: 17px;
          margin-bottom: 3%;
          svg {
            width: 6vw;
            height: 6vw;
          }
          .data {
            font-weight: 700;
            font-size: 1.1rem;
          }
          .tag {
            font-size: 0.7rem;
            font-weight: 500;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .root {
    min-height: auto;
    .header {
      width: 100%;
      margin-bottom: 3%;
      margin-top: 2%;
      .headerContainer {
        gap: 20px;
        .userImage {
          width: 6vw;
          height: 6vw;
        }
        .userName {
          p {
            font-size: 1.5rem;
            font-weight: 600;
          }
        }
      }
      .headerButtons {
        width: 22vw;
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        .button {
          padding: 1% 12%;
        }
        .buttonChat {
          padding: 1% 12%;
        }
      }
    }
    .graph {
      .textContainer {
        .graphSelector {
          width: 18vw;
          .selector {
            height: 4vw;
          }
        }
      }
      .chartContainer {
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 3%;
        div {
          width: 100%;
          .text {
            font-size: 1.4rem;
            font-weight: 600;
            color: #0d30ac;
            margin-bottom: 2%;
          }
          .chart {
            width: 100%;
            height: 30vh;
            padding: 3%;
            border-radius: 10px;
            background: linear-gradient(
              180deg,
              rgba(221, 221, 221, 0.12) 0%,
              rgba(221, 221, 221, 0.066) 100%
            );
          }
        }
        .values {
          width: 80%;
          height: 100%;
          margin: 4% auto;
          flex-direction: row;
          gap: 40px;
          justify-content: space-around;
          align-items: center;
          .valueCard {
            // width: 16vw;
            background-color: #f5f5f5;
            .head {
              font-size: 1.4rem;
            }
            padding: 2%;
            border-radius: 8px;
            box-shadow: 0px 0.5px 0.5px 1px lightgray;
            line-height: 17px;
            margin-bottom: 3%;
            div {
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                width: 13vw;
                height: 13vw;
              }
              div {
                display: flex;
                flex-direction: column;
                gap: 5px;
                .data {
                  font-weight: 700;
                  font-size: 1.5rem;
                }
                .tag {
                  font-size: 1rem;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .root {
    min-height: auto;
    .header {
      .headerButtons {
        width: 28vw;
      }
    }
    .graph {
      .chartContainer {
        .values {
          width: 70%;
          .valueCard {
            .head {
              font-size: 1.2rem;
            }
            padding: 2%;
            div {
              div {
                .data {
                  font-weight: 600;
                  font-size: 1.2rem;
                }
                .tag {
                  font-size: 0.9rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .root {
    .header {
      .headerContainer {
        .userImage {
          width: 9vw;
          height: 9vw;
        }
        .userName {
          p {
            font-size: 1.2rem;
          }
        }
      }
      .headerButtons {
        width: 36vw;
        font-size: 0.9rem;
        .button {
          padding: 2px 12px;
        }
        .buttonChat {
          padding: 2px 12px;
        }
      }
    }
    .graph {
      .textContainer {
        .graphSelector {
          width: 30vw;
          .selector {
            height: 7vw;
            width: 20vw;
          }
        }
      }
      .chartContainer {
        div {
          .text {
            font-size: 1.2rem;
          }
        }
        .values {
          width: 90%;
          gap: 20px;
          .valueCard {
            .head {
              font-size: 1rem;
            }
            div {
              svg {
                width: 16vw;
                height: 16vw;
              }
              div {
                gap: 0;
                .data {
                  font-size: 1rem;
                }
                .tag {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
