.rootHealthData {
  width: 100%;
  height: 100%;

  .heading {
    margin-bottom: 2%;
    p {
      font-size: 1.6rem;
      font-weight: 600;
      color: #0d30ac;
    }
  }

  .graphs {
    display: flex;
    justify-content: space-around;
    .heartBeat {
      .head {
        margin-bottom: 4%;
        p {
          font-size: 1.3rem;
          font-weight: 600;
        }
      }
    }

    .weight {
      .head {
        margin-bottom: 4%;
        p {
          font-size: 1.3rem;
          font-weight: 600;
        }
      }
    }
  }
}
.pieChart {
  margin: auto;
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  .progress {
    svg {
      width: 15vw;
      height: 15vw;
    }
  }
  .data {
    font-weight: 600;
    font-size: 40px;
    color: #000000;
  }
  .data1 {
    font-weight: 600;
    font-size: 40px;
    color: white;
  }

  .tag {
    font-size: 22px;
    line-height: 15px;
    font-weight: 600;
    color: #000000;
  }
  .tag1 {
    font-size: 22px;
    line-height: 15px;
    font-weight: 600;
    color: white;
  }
}
@media only screen and (max-width: 1024px) {
  .rootHealthData {
    .heading {
      p {
        font-size: 1.5rem;
      }
    }

    .graphs {
      width: 100%;
      margin-left: 0;
      justify-content: space-between;
      .heartBeat {
        .head {
          p {
            font-size: 1.2rem;
          }
        }
      }

      .weight {
        .head {
          p {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .pieChart {
    margin: auto;
    width: 21vw;
    height: 21vw;
    .progress {
      svg {
        width: 19vw;
        height: 19vw;
      }
    }
    .data {
      font-size: 1.8rem;
    }
    .data1 {
      font-size: 1.8rem;
    }

    .tag {
      font-size: 1.2rem;
      line-height: 15px;
    }
    .tag1 {
      font-size: 1.2rem;
      line-height: 15px;
    }
  }
}
@media only screen and (max-width: 820px) {
  .pieChart {
    width: 24vw;
    height: 24vw;
    .progress {
      svg {
        width: 21vw;
        height: 21vw;
      }
    }
    .data {
      font-size: 1.5rem;
    }
    .data1 {
      font-size: 1.5rem;
    }

    .tag {
      font-size: 1.1rem;
    }
    .tag1 {
      font-size: 1.1rem;
    }
  }
}
@media only screen and (max-width: 450px) {
  .rootHealthData {
    .heading {
      p {
        font-size: 1.1rem;
      }
    }

    .graphs {
      .heartBeat {
        .head {
          p {
            font-size: 1rem;
            text-align: center;
          }
        }
      }

      .weight {
        .head {
          p {
            font-size: 1rem;
            text-align: center;
          }
        }
      }
    }
  }
  .pieChart {
    width: 24vw;
    height: 24vw;
    .progress {
      svg {
        width: 21vw;
        height: 21vw;
      }
    }
    .data {
      font-size: 0.9rem;
    }
    .data1 {
      font-size: 0.9rem;
    }

    .tag {
      font-size: 12px;
    }
    .tag1 {
      font-size: 12px;
    }
  }
}
