.rootLogin {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .bgImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/loginbackground.jpeg");
    background-size: cover;
    background-position: center;
    z-index: -1; /* Ensure the background image is behind the overlay component */
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.509);
  }
  .containerMain {
    width: 30vw;
    height: 65vh;
    background-color: #f8f8f8;
    margin: auto;
    position: absolute;
    z-index: 2;
    border-radius: 3%;
    padding: 3%;
    .header {
      display: flex;
      justify-content: center;
      height: auto;
      p {
        font-weight: 600;
        font-size: 3.5rem;
        color: #0d30ac;
      }
    }

    .container {
      width: 100%;
      margin: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .email {
        width: 100%;
        margin-bottom: 3%;
        .label {
          font-weight: 600;
          font-size: 1.2rem;
          color: #614d8f;
          margin-bottom: 15px;
        }

        .input {
          width: 100%;
          height: 6vh;
          background-color: #eeeeee;
          border-radius: 5px;
          display: flex;
          align-items: center;
          .icon {
            width: 4vw;
            height: 100%;
            .emailIcon {
              width: 1.8vw;
              height: 1.8vw;
              color: #0d30ac;
            }
          }

          input {
            flex-grow: 1;
            height: 100%;
            outline: none;
            border: none;
            background-color: #eeeeee;
            font-size: 1rem;
            font-weight: 500;
            color: #0d30ac;
            &:focus {
              outline: none;
            }
          }
        }
      }

      .password {
        width: 100%;
        .label {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 15px;
          .pass {
            font-weight: 600;
            font-size: 1.2rem;
            color: #614d8f;
            display: block;
          }

          .forgot {
            font-size: 0.9rem;
            font-weight: 600;
            color: #0d30ac;
            &:hover {
              cursor: pointer;
            }
          }
        }

        .input {
          width: 100%;
          height: 6vh;
          background-color: #eeeeee;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .icon {
            width: 4vw;
            height: 100%;
            .passwordIcon {
              width: 1.8vw;
              height: 1.8vw;
              color: #0d30ac;
            }
          }

          input {
            flex-grow: 1;
            height: 100%;
            outline: none;
            border: none;
            background-color: #eeeeee;
            font-size: 1rem;
            font-weight: 500;
            color: #0d30ac;

            &:focus {
              outline: none;
            }
          }
          .passicon {
            width: 4vw;
            height: 100%;
            .EyeIcon {
              font-size: 1.4rem;
              color: gray;
            }
          }
        }
      }

      .buttons {
        margin-top: 10%;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .signin {
          width: 40%;
          height: 6vh;
          background-color: #0d30ac;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 1rem;
          }
          &:hover {
            cursor: pointer;
          }
        }

        .signup {
          width: 12vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #614d8f;
          font-weight: 600;
          font-size: 1rem;
          .link {
            text-decoration: underline;
            line-height: 15px;
          }
        }
      }
    }
  }
  .errorpopup {
    width: 20vw;
    height: 10vh;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    border-radius: 5px;
    background-color: rgb(255, 78, 38);
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 6;
  }

  .errorpopup p {
    color: white;
  }
  @media only screen and (max-width: 1024px) {
    .errorpopup {
      width: 40vw;
      height: 8vh;
    }
    .containerMain {
      width: 70vw;
      height: 52vh;
      padding: 5%;
      margin: auto;
      .header {
        width: 100%;
        height: auto;
        margin-bottom: 3%;
        p {
          font-size: 4rem;
        }
      }
      .container {
        width: 100%;
        .email {
          width: 100%;
          margin-bottom: 5%;
          .label {
            font-size: 1.5rem;
            margin-bottom: 10px;
          }
          .input {
            height: 5vh;
            .icon {
              width: 10vw;
              height: 100%;
              .emailIcon {
                width: 4vw;
                height: 4vw;
                color: #0d30ac;
              }
            }
          }
        }

        .password {
          width: 100%;
          .label {
            margin-bottom: 10px;
            .pass {
              font-size: 1.4rem;
            }
            .forgot {
              font-size: 1.1rem;
              font-weight: 600;
            }
          }

          .input {
            height: 5vh;
            .icon {
              width: 10vw;
              height: 100%;
              .passwordIcon {
                width: 4vw;
                height: 4vw;
                color: #0d30ac;
              }
            }

            .passicon {
              width: 10vw;
              height: 100%;
              .EyeIcon {
                font-size: 2rem;
                color: gray;
              }
            }
          }
        }

        .buttons {
          width: 30vw;
          height: auto;
          flex-direction: column;
          margin-top: 10%;
          .signin {
            width: 80%;
            height: 5vh;
            border-radius: 5px;
            p {
              font-size: 1.4rem;
            }
          }

          .signup {
            font-size: 1.3rem;
            width: 90%;
            margin-top: 2%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 820px) {
    .containerMain {
      width: 70vw;
      height: 52vh;
      .header {
        margin-bottom: 3%;
        p {
          font-size: 3rem;
        }
      }
      .container {
        .buttons {
          width: 35vw;
          .signup {
            p,
            .link {
              font-size: 1rem;
            }
          }
        }
        .password {
          .input {
            .passicon {
              .EyeIcon {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .errorpopup {
      width: 70vw;
    }
    .containerMain {
      width: 90vw;
      height: 50vh;
      margin: auto;
      .header {
        width: 100%;
        height: auto;
        margin-bottom: 5%;
        p {
          font-size: 2rem;
        }
      }

      .container {
        width: 90%;
        .email {
          width: 100%;
          margin-bottom: 8%;
          .label {
            font-size: 1.1rem;
            margin-bottom: 10px;
          }
          .input {
            height: 5vh;
            .icon {
              width: 16vw;
              height: 100%;
              .emailIcon {
                width: 5vw;
                height: 5vw;
                color: #0d30ac;
              }
            }
          }
        }

        .password {
          width: 100%;
          .label {
            margin-bottom: 10px;
            .pass {
              font-size: 1.1rem;
            }
            .forgot {
              font-size: 0.8rem;
              font-weight: 600;
            }
          }

          .input {
            height: 5vh;
            .icon {
              width: 20vw;
              height: 100%;
              .passwordIcon {
                width: 5vw;
                height: 5vw;
                color: #0d30ac;
              }
            }

            .passicon {
              width: 20vw;
              height: 100%;
              .EyeIcon {
                font-size: 1.4rem;
                color: gray;
              }
            }
          }
        }

        .buttons {
          width: 50vw;
          height: auto;
          flex-direction: column;
          margin-top: 15%;
          .signin {
            width: 50vw;
            height: 5vh;
            border-radius: 5px;
            p {
              font-size: 1rem;
            }
          }

          .signup {
            width: 60vw;
            margin-top: 3%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 380px) {
    .containerMain {
      height: 62vh;
    }
  }
}
