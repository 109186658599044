.rootExercises {
  width: 100%;
  height: 100%;
  padding: 2% 3%;
  // position: relative;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    margin-bottom: 2%;
    color: #0d1dac;
    .title {
      font-size: 2rem !important;
      font-weight: 600;
      letter-spacing: 0em;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .outerContainer {
      width: 100%;
      min-height: 80vh;
      padding: 1% 3%;
      border-radius: 14px;
      border: 3px solid #4370cb3c;
      .searchBarNotificationContainer {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 2% auto;
        .searchBarContainer {
          flex-grow: 1;
          display: flex;
          align-items: center;
          border: 1px solid #0d30ac;
          border-radius: 10px;
          height: 2.5vw;
          padding: 5px;
          .searchIconContainer {
            border-radius: 50%;
            padding: 5px;
          }

          .searchInput {
            font-size: 1rem;
            width: 100%;
            box-shadow: none;
            border: 0;
            outline: 0;
            padding: 5px;
            margin-left: 5px;
          }
        }

        .notificationIconContainer {
          margin-left: 2%;
          border-radius: 8px;
          background-color: #3855b7;
          width: 5vw;
          height: 5vh;
          position: relative;
          cursor: pointer;
          .dropDown {
            padding: 5% 2%;
            width: 15vw;
            height: auto;
            border: 3px solid #3856b784;
            border-radius: 5px;
            background-color: white;
            box-shadow: 0px 1px 3px 1px lightgray;
            position: absolute;
            left: -320%;
            top: 50%;
            color: #0d30ac;
            text-align: center;
            hr {
              border-width: 1px;
              width: 100%;
            }
            .dropDownContent {
              padding: 1% 3%;
              width: 100%;
              input {
                margin: 2% 4%;
                width: 1vw;
                cursor: pointer;
              }
            }
            .button {
              height: 4vh;
              margin-bottom: 5%;
              border: 1px solid #0d30ac;
              color: #0d30ac;
            }
          }
          .dropDownContent input :checked {
            background-color: #0d30ac;
          }
        }
      }
      .listContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        padding-top: 1%;
        .card {
          width: 100%;
          height: 11vh;
          margin-bottom: 1em;
          background-color: #edf0f3;
          padding: 0 2%;
          display: flex;
          flex-direction: row;
          align-items: center;
          // justify-content: center;
          justify-content: space-between;
          border-radius: 1vw;
          box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
          .imageContainer {
            width: 100%;
            display: flex;
            align-items: center;
            // background-color: red;
            img {
              width: 2.5vw;
              height: 2.5vw;
              border-radius: 50%;
              overflow: hidden;
              object-fit: cover;
              margin-right: 2%;
              border: 1px solid #4370cb;
            }
            .infoDiv {
              // flex-grow: 1;
              display: flex;
              gap: 20px;
              .info {
                gap: 20px;
              }
              p {
                font-weight: 500;
                font-size: 1.34rem;
                color: #0d1dac;
                // color: #000000;
              }
            }
          }

          .buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 19vw;
            .accept,
            .decline {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 12px;
              width: 7vw;
              height: 4vh;
              font-weight: 500;
              font-size: 1.1rem;
              transition: color 0.3s;
            }
            .accept {
              color: white;
              background-color: #0d30ac;
            }
            .decline {
              border: 2px solid #4370cb3c;
              color: #0d30ac;
              background-color: white;
            }

            .accept:hover {
              cursor: pointer;
              background-color: #0d30acd6;
              transition: background-color 0.3s;
            }

            .decline:hover {
              cursor: pointer;
              background-color: rgba(255, 255, 255, 0.55);
              transition: background-color 0.3s;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-height: 1200px) {
  .rootExercises {
    .container {
      .outerContainer {
        min-height: 90vh;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .rootExercises {
    width: 100%;
    height: 100%;
    .container {
      .outerContainer {
        border: 2px solid #4370cb3c;
        .searchBarNotificationContainer {
          .searchBarContainer {
            height: 4vh;
            font-size: 1.2rem;
            .searchIconContainer {
              padding: 0 2%;
            }
            .searchInput {
              padding: 0 2%;
            }
          }

          .notificationIconContainer {
            width: 15vw;
            height: 4vh;
            .dropDown {
              padding: 5% 2%;
              width: 30vw;
              height: auto;
              left: -200%;
              top: 100%;
              color: #0d30ac;
              text-align: center;
              hr {
                border-width: 1px;
                width: 100%;
              }
              .dropDownContent {
                padding: 1%;
                width: 100%;
                align-items: center;
                font-size: 1rem;
                input {
                  margin: 2% 4%;
                  width: 4vw;
                  cursor: pointer;
                }
              }
              .button {
                height: 3vh;
                font-size: 1rem;
                margin-bottom: 5%;
                border: 1px solid #0d30ac;
                color: #0d30ac;
              }
            }
          }
        }
        .listContainer {
          .card {
            margin-top: 3%;
            width: 100%;
            flex-direction: column;
            height: auto;
            padding: 3%;
            .imageContainer {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .profilePicture {
                width: 9vw;
                height: 8vw;
                margin: 0;
                margin-right: 2%;
              }
              .infoDiv {
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 0;
                .info {
                  flex-direction: column;
                  gap: 0;
                  .name {
                    font-weight: 600;
                    font-size: 1.5rem;
                    color: #0d1dac;
                  }
                }
                .info1 {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: flex-end;
                  p {
                    text-align: right;
                  }
                }
                p {
                  font-size: 1.2rem;
                  color: #000000;
                }
              }
            }

            .buttons {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              margin-top: 2%;
              gap: 10px;
              .accept,
              .decline {
                width: 18vw;
                height: 3.5vh;
                font-weight: 500;
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .rootExercises {
    // width: 94vw;
    margin: 0;
    // padding: 0;
    .header {
      font-size: 0.7rem;
      .title {
        font-size: 1.2rem !important;
        font-weight: 600;
        letter-spacing: 0em;
      }
      .date {
        display: none;
      }
    }

    .container {
      width: 100%;
      .outerContainer {
        flex-grow: 1;
        .searchBarNotificationContainer {
          margin-bottom: 0;
          .searchBarContainer {
            height: 4vh;
            border-radius: 8px;
            .searchInput {
              padding: 0 4px;
            }
          }

          .notificationIconContainer {
            width: 15vw;
            height: 4vh;
            font-size: 0.9rem;
            .dropDown {
              padding: 5% 2%;
              width: 50vw;
              height: auto;
              left: -280%;
              top: 100%;
              color: #0d30ac;

              text-align: center;
              hr {
                border-width: 1px;
                width: 100%;
              }
              .dropDownContent {
                padding: 1%;
                width: 100%;
                align-items: center;
                font-size: 1rem;
                input {
                  margin: 2% 4%;
                  width: 4vw;
                  cursor: pointer;
                }
              }
              .button {
                height: 3vh;
                font-size: 1rem;
                margin-bottom: 5%;
                border: 1px solid #0d30ac;
                color: #0d30ac;
              }
            }
          }
        }
        .listContainer {
          width: 100%;
          .card {
            margin-top: 3%;
            margin-bottom: 0;
            flex-direction: column;
            height: auto;
            padding: 2%;
            .imageContainer {
              width: 100%;
              .profilePicture {
                width: 12vw;
                height: 10vw;
                margin: 0;
                margin-right: 2%;
              }
              .infoDiv {
                .info {
                  .name {
                    font-size: 1rem;
                  }
                }
                p {
                  font-size: 0.8rem;
                }
              }
            }

            .buttons {
              .accept,
              .decline {
                font-size: 0.7rem;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 380px) {
  .rootExercises {
    .container {
      .outerContainer {
        .searchBarNotificationContainer {
          .searchBarContainer {
            border-radius: 6px;
            .searchInput {
              font-size: 0.9rem;
            }
            .searchIconContainer {
              svg {
                font-size: 0.9rem;
              }
            }
          }

          .notificationIconContainer {
            width: 18vw;
            font-size: 0.8rem;
            .dropDown {
              width: 53vw;
              .dropDownContent {
                font-size: 0.9rem;
              }
              .button {
                font-size: 0.8rem;
              }
            }
          }
        }
        .listContainer {
          .card {
            .imageContainer {
              .infoDiv {
                .info {
                  .name {
                    font-size: 0.9rem;
                  }
                }
                p {
                  font-size: 0.7rem;
                }
              }
            }

            .buttons {
              .accept,
              .decline {
                font-size: 0.7rem;
              }
            }
          }
        }
      }
    }
  }
}
