.exerciseDetailsContainer {
  padding: 2% 3%;
  .heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    margin-bottom: 2%;
    color: #0d1dac;
    .title {
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: 0em;
    }
  }
  .outerContainer {
    min-height: 80vh;
    padding: 1% 3%;
    border-radius: 14px;
    border: 3px solid #4370cb3c;
    .searchBarNotificationContainer {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 2% auto;
      .searchBarContainer {
        flex-grow: 1;
        display: flex;
        align-items: center;
        border: 1px solid #0d30ac;
        border-radius: 10px;
        padding: 5px;
        height: 5vh;
        .searchIconContainer {
          border-radius: 50%;
          padding: 5px;
        }

        .searchInput {
          font-size: 1rem;
          width: 100%;
          box-shadow: none;
          border: 0;
          outline: 0;
          padding: 5px;
          margin-left: 5px;
        }
      }
      .button {
        width: 5vw;
        height: 5vh;
        margin-left: 2%;
        color: #0d30ac;
        font-size: 1.2rem;
        font-weight: 500;
        border: 1px solid #0d30ac;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .notificationIconContainer {
        border-radius: 8px;
        position: relative;
        font-size: 1.2em;
        font-weight: 500;
        color: #e7f3ff;
        background-color: #3855b7;
        width: 5vw;
        height: 5vh;
        cursor: pointer;
        margin-left: 2%;
        .dropDown {
          padding: 5% 2%;
          width: 15vw;
          height: auto;
          border: 3px solid #3856b784;
          border-radius: 5px;
          background-color: white;
          box-shadow: 0px 1px 3px 1px lightgray;
          position: absolute;
          left: -320%;
          top: 50%;
          color: #0d30ac;
          text-align: center;
          hr {
            border-width: 1px;
            width: 100%;
          }
          .dropDownContent {
            padding: 1% 3%;
            width: 100%;
            display: flex;
            align-items: center;
            input {
              margin: 2% 4%;
              width: 1vw;
              cursor: pointer;
            }
          }
          .button {
            height: 4vh;
            margin-bottom: 5%;
            border: 1px solid #0d30ac;
            color: #0d30ac;
          }
        }
        .dropDownContent input :checked {
          background-color: #0d30ac;
        }
      }
    }

    .container {
      margin: 4% 0;
      display: flex;
      flex-wrap: wrap;
      //   flex-direction: row;
      justify-content: space-between;
      gap: 40px;
      width: 100%;
      .card {
        background-color: #edf0f3;
        padding: 1.2rem;
        border-radius: 1vw;
        display: flex;
        align-items: center;
        .tag {
          height: 100%;
          .exerciseName {
            font-weight: 700;
            font-size: 1.3rem;
            margin-bottom: 1%;
          }
          .musclesInvolved {
            width: 27vw;
            padding: 2px 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            font-weight: 600;
            color: #0d1dac;
            .musclesName {
              font-size: 0.7rem;
              background-color: white;
              border-radius: 8px;
              padding: 1.5% 2.5%;
            }
          }
          .title {
            margin-bottom: 4px;
            color: #0d1dac;
            font-size: 1rem;
            font-weight: 600;
          }
        }
        .playButton {
          width: 6vw;
          height: 6vw;
          display: flex;
          align-items: center;
          border-radius: 15px;
          border: 1px solid #0d62acab;
          background-color: white;
          justify-content: center;
          color: #0d30ac;
          .icon {
            font-size: 2.5rem;
          }
          p {
            text-align: center;
            font-weight: 500;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media only screen and (min-height: 1200px) {
  .exerciseDetailsContainer {
    .outerContainer {
      min-height: 90vh;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .exerciseDetailsContainer {
    .header {
      font-size: 1rem;
      .title {
        font-size: 1.4rem !important;
      }
    }
    .outerContainer {
      .searchBarNotificationContainer {
        .searchBarContainer {
          height: 4vh;
        }

        .notificationIconContainer {
          width: 10vw;
          height: 4vh;
          .dropDown {
            width: 26vw;
            left: -230%;
            top: 100%;
            hr {
              border-width: 1px;
              width: 100%;
            }
            .dropDownContent {
              font-size: 1.2rem;
              input {
                width: 2.4vw;
              }
            }
            .button {
              height: 3vh;
              font-size: 1.2rem;
            }
          }
        }
        .button {
          width: 10vw;
          height: 4vh;
        }
      }
      .container {
        margin-top: 3%;
        gap: 10px;
        .card {
          width: 100%;
          padding: 1rem;
          margin-bottom: 1%;
          border-radius: 10px;
          .tag {
            width: 100%;
            height: 100%;
            .exerciseName {
              font-size: 1.5rem;
              margin-bottom: 1%;
            }
            .musclesInvolved {
              width: auto;
              padding: 2px 0px;
              gap: 5px;
              .musclesName {
                font-size: 0.7rem;
                padding: 1.5% 2.5%;
              }
            }
            .title {
              margin-bottom: 4px;
              font-size: 1.2rem;
            }
          }
          .playButton {
            width: 15vw;
            height: 100%;
            display: flex;
            align-items: center;
            border-radius: 15px;
            justify-content: center;
            .icon {
              font-size: 3.5rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .exerciseDetailsContainer {
    .heading {
      .title {
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 0em;
      }
    }
    .outerContainer {
      min-height: 72vh;
      .searchBarNotificationContainer {
        margin: 4% auto;
        .searchBarContainer {
          height: 10vw;

          .searchInput {
            padding: 0 4px;
          }
          .searchIconContainer {
            svg {
              font-size: 0.9rem;
            }
          }
        }

        .notificationIconContainer {
          width: 15vw;
          height: 10vw;
          font-size: 0.9rem;
        }
        .button {
          width: 15vw;
          height: 10vw;
          font-size: 0.9rem;
        }
      }
      .container {
        margin-top: 3%;
        gap: 10px;
        .card {
          width: 100%;
          padding: 1rem;
          border-radius: 10px;
          position: relative;
          .tag {
            height: 100%;
            width: 100%;
            .exerciseName {
              font-size: 1.3rem;
              margin-bottom: 1%;
            }
            .musclesInvolved {
              width: auto;
              padding: 2px 0px;
              gap: 5px;
              .musclesName {
                font-size: 0.7rem;
                padding: 1.5% 2.5%;
              }
            }
            .title {
              margin-bottom: 4px;
              font-size: 1rem;
            }
          }
          .playButton {
            height: 100%;
            display: flex;
            align-items: flex-start;
            border-radius: 15px;
            border: none;
            background-color: transparent;
            justify-content: center;
            color: #0d30ac;
            position: absolute;
            right: 3%;
            top: 10%;
            .icon {
              font-size: 3rem;
            }
            p {
              display: none;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
