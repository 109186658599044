.rootSignup {
  width: 100%;
  height: 100%;
  position: relative;
  .bgImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/loginbackground.jpeg");
    background-size: cover;
    background-position: center;
    z-index: -1; /* Ensure the background image is behind the overlay component */
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.509);
  }
  .container {
    width: 74vw;
    height: auto;
    background-color: white;
    margin: 4% auto;
    padding: 3%;
    z-index: 2;
    border-radius: 16px;

    .content {
      height: auto;
      margin: auto;
      .header {
        width: 100%;
        p {
          font-weight: 700;
          font-size: 3.4rem;
          color: #0d30ac;
          text-align: center;
        }
      }

      .form {
        width: 100%;
        height: auto;
        margin: 2% auto;
        .column {
          display: flex;
          flex-direction: row;
          gap: 30px;
          margin-top: 2%;
          .inputBox {
            display: flex;
            flex: 1;
            flex-direction: column;
            div {
              width: 100%;
              height: 7vh;
              padding: 3%;
              color: #817c7c;
              font-size: 1.2rem;
              font-weight: 400;
              border-radius: 5px;
              background: #f8f8f8;
              display: flex;
              align-items: center;
              select {
                font-weight: 500;
                width: 100%;
                height: 100%;
                background: transparent;
                outline: none;
                box-shadow: none;
              }
            }
            .box {
              margin-top: 1%;
              width: 100%;
              display: flex;
              align-items: center;
              position: relative;
              .SecurityIcon {
                color: rgb(60, 60, 60);
                width: 25px;
                height: 25px;
              }
              .EyeIcon {
                cursor: pointer;
                height: 42px;
                width: 25px;
                color: rgb(107, 106, 106);
              }

              input {
                width: 100%;
                height: 100%;
                background: #f8f8f8;
                border-radius: 8px;
                border: none;
                padding: 3%;
              }
            }

            .icon {
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 10%;
            }
          }
        }

        .bio {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 30px;

          textarea {
            width: 100%;
            height: 30vh;
            margin-top: 5px;
            border-radius: 5px;
            background: #f8f8f8;
            color: #817c7c;
            font-size: 1.2rem;
            font-weight: 500;
            padding: 2% 3%;
            overflow-y: scroll;
          }
        }

        .footer {
          margin-top: 50px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .signup {
            width: 12vw;
            height: 6vh;
            background-color: #0d30ac;
            color: white;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              cursor: pointer;
            }
          }
          p {
            text-align: center;
            margin-top: 1%;
            font-size: 1.1rem;
            font-weight: 400;
            span {
              font-weight: 500;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  input {
    width: 100%;
    height: 7vh;
    padding: 3%;
    color: #817c7c;
    font-size: 1.2rem;
    font-weight: 400;
    border-radius: 5px;
    background: #f8f8f8;
    font-weight: 500;
  }
  label {
    color: #614d8f;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .errorpopup {
    width: 20vw;
    height: 10vh;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    border-radius: 5px;
    background-color: rgb(255, 78, 38);
    position: absolute;
    bottom: 5%;
    right: 5%;
    z-index: 6;
  }

  .errorpopup p {
    color: white;
  }

  @media only screen and (max-width: 1024px) {
    height: 100vh;
    input {
      height: 4vh;
    }
    .errorpopup {
      width: 40vw;
      height: 8vh;
      bottom: 20%;
      right: 5%;
    }
    .container {
      .content {
        .form {
          .column {
            .inputBox {
              div {
                height: 4vh;
              }
            }
          }
          .bio {
            textarea {
              height: 16vh;
            }
          }
          .footer {
            .signup {
              width: 18vw;
              height: 4vh;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 820px) {
    input {
      font-size: 1rem;
    }
    label {
      font-size: 1rem;
    }
    .container {
      .content {
        .header {
          p {
            font-size: 3rem;
          }
        }
        .form {
          .column {
            .inputBox {
              .box {
                .SecurityIcon {
                  width: 20px;
                  height: 20px;
                }
                .EyeIcon {
                  height: 35px;
                  width: 20px;
                }
              }
              div {
                font-size: 1rem;
              }
            }
          }
          .bio {
            textarea {
              font-size: 1rem;
            }
          }
          .footer {
            .signup {
              width: 18vw;
              height: 4vh;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    width: 100%;
    height: 100%;
    .bgImage {
      height: 100%;
    }
    .overlay {
      height: 100%;
    }
    .container {
      width: 90vw;
      border-radius: 15px;
      .content {
        width: 80vw;
        padding-top: 15px;
        .header {
          p {
            font-size: 2.5rem;
          }
        }

        .form {
          margin: 15px auto;
          .column {
            flex-direction: column;
            margin-top: 5px;
            gap: 10px;
            .inputBox {
              .box {
                margin-top: 1%;
                .SecurityIcon {
                  height: 5vw;
                  width: 5vw;
                }
                .EyeIcon {
                  height: 5vw;
                  width: 5vw;
                }

                input {
                  border-radius: 5px;
                  font-size: 1rem;
                }
              }
              div {
                height: 5vh;
                select {
                  font-size: 1rem;
                }
              }
              label {
                font-size: 1rem;
                margin: 0;
              }
              input {
                font-size: 1rem;
                height: 5vh;
                border-radius: 5px;
              }

              .icon {
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 10%;
              }
            }
          }

          .bio {
            margin-top: 10px;
            margin-bottom: 10px;
            label {
              font-size: 1rem;
              margin: 0;
            }

            textarea {
              min-height: 16vh;
              font-size: 1rem;
              height: 16vh;
            }
          }

          .footer {
            margin-top: 8%;
            .signup {
              width: 36vw;
              height: 5vh;
              svg {
                font-size: 0.8rem;
              }
            }
            p {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
    .errorpopup {
      width: 75vw;
      font-size: 1rem;
      bottom: 12%;
      right: 5%;
    }
  }
}
