.rootClients {
  width: 100%;
  height: 100%;
  padding: 2% 3%;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    margin-bottom: 2%;
    color: #0d1dac;
    .title {
      font-size: 2rem !important;
      font-weight: 600;
      letter-spacing: 0em;
    }
  }
  .outerContainer {
    min-height: 80vh;
    padding: 1% 3%;
    border-radius: 14px;
    border: 3px solid #4370cb3c;
    .searchBarNotificationContainer {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 2% auto;
      .searchBarContainer {
        flex-grow: 1;
        display: flex;
        align-items: center;
        border: 1px solid #0d30ac;
        border-radius: 10px;
        height: 2.5vw;
        padding: 2px 5px;
        .searchIconContainer {
          border-radius: 50%;
          padding: 5px;
        }

        .searchInput {
          font-size: 1rem;
          width: 100%;
          box-shadow: none;
          border: 0;
          outline: 0;
          padding: 5px;
          margin-left: 5px;
        }
      }
      .notificationIconContainer {
        border-radius: 8px;
        position: relative;
        font-size: 1.2em;
        font-weight: 500;
        color: #e7f3ff;
        background-color: #3855b7;
        width: 5vw;
        height: 5vh;
        cursor: pointer;
        margin-left: 2%;
        .dropDown {
          padding: 5% 2%;
          width: 15vw;
          height: auto;
          border: 3px solid #3856b784;
          border-radius: 5px;
          background-color: white;
          box-shadow: 0px 1px 3px 1px lightgray;
          position: absolute;
          left: -320%;
          top: 50%;
          color: #0d30ac;
          text-align: center;
          hr {
            border-width: 1px;
            width: 100%;
          }
          .dropDownContent {
            padding: 1% 3%;
            width: 100%;
            input {
              margin: 2% 4%;
              width: 1vw;
              cursor: pointer;
            }
          }
          .button {
            height: 4vh;
            margin-bottom: 5%;
            border: 1px solid #0d30ac;
            color: #0d30ac;
          }
        }
        .dropDownContent input :checked {
          background-color: #0d30ac;
        }
      }
    }
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 1%;
      .listContainer {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        gap: 1.3rem;
        width: 100%;

        .card {
          width: 24.2vw;
          margin-bottom: 0.6%;
          background-color: #edf0f3;
          padding: 1.8rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          // justify-content: center;
          justify-content: space-between;
          border-radius: 1vw;

          .imageContainer {
            width: 15%;
            img {
              border: 1px solid #5b8ff7;
              width: 3vw;
              height: 3vw;
              border-radius: 50%;
              overflow: hidden;
              object-fit: cover;
            }
          }

          .textContainer {
            // background-color: yellow;
            width: 80%;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .username {
              p {
                font-weight: 600;
                font-size: 1.53rem;
                color: #0d1dac;
                line-height: 26px;
              }
            }

            .buttons {
              margin-right: 1%;
              width: 5vw;
              height: 2vw;
              border-radius: 40px;
              border: 1px solid #361f69;
              background-color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              p {
                // padding: 1rem;
                font-weight: 600;
                font-size: 1.28rem;
                color: #361f69;
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-height: 1200px) {
  .rootClients {
    .outerContainer {
      min-height: 90vh;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .rootClients {
    width: 100%;
    height: 100%;
    .outerContainer {
      .searchBarNotificationContainer {
        .searchBarContainer {
          height: 4vh;
          font-size: 1.2rem;
          .searchIconContainer {
            padding: 0 2%;
          }
          .searchInput {
            padding: 0 2%;
          }
        }
        .notificationIconContainer {
          width: 15vw;
          height: 4vh;
          .dropDown {
            padding: 5% 2%;
            width: 30vw;
            height: auto;
            left: -200%;
            top: 100%;
            color: #0d30ac;
            text-align: center;
            hr {
              border-width: 1px;
              width: 100%;
            }
            .dropDownContent {
              padding: 1%;
              width: 100%;
              align-items: center;
              font-size: 1rem;
              input {
                margin: 2% 4%;
                width: 4vw;
                cursor: pointer;
              }
            }
            .button {
              height: 3vh;
              font-size: 1rem;
              margin-bottom: 5%;
              border: 1px solid #0d30ac;
              color: #0d30ac;
            }
          }
        }
      }
      .container {
        width: 100%;
        .listContainer {
          justify-content: space-between;
          .card {
            width: 35vw;
            // flex-grow: 1;
            .imageContainer {
              .profilePicture {
                width: 4vw;
                height: 4vw;
              }
            }
            .textContainer {
              .username {
                p {
                  line-height: auto;
                }
              }
              .buttons {
                width: 8vw;
                height: 2.4vh;
                p {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .rootClients {
    width: 100vw;
    height: 100%;
    .outerContainer {
      .container {
        width: 100%;
        .listContainer {
          .card {
            width: 42vw;
          }

          .card {
            padding: 1.2rem;
            border-radius: 10px;
            .imageContainer {
              .profilePicture {
                width: 6vw;
                height: 6vw;
              }
            }

            .textContainer {
              .username {
                p {
                  font-size: 1.43rem;
                }
              }

              .buttons {
                width: 10vw;
                height: 3.2vh;
                p {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .rootClients {
    .header {
      font-size: 0.8rem;
      .title {
        font-size: 1.6rem !important;
      }
    }
    .outerContainer {
      .searchBarNotificationContainer {
        .searchBarContainer {
          height: 4.3vh;
          .searchInput {
            padding: 0 4px;
            font-size: 0.9rem;
          }
          .searchIconContainer {
            svg {
              font-size: 0.9rem;
            }
          }
        }

        .notificationIconContainer {
          font-size: 0.9rem;
          .dropDown {
            width: 50vw;
            left: -280%;
            .dropDownContent {
              font-size: 1rem;
              input {
                margin: 2% 4%;
                width: 4vw;
              }
            }
          }
        }
      }
      .container {
        width: 100%;
        .listContainer {
          flex-direction: column;
          gap: 0;
          .card {
            width: 100%;
            margin-bottom: 3%;
          }
          .card {
            .imageContainer {
              width: 15vw;
              .profilePicture {
                width: 10vw;
                height: 10vw;
              }
            }
            .textContainer {
              .username {
                p {
                  font-size: 1.3rem;
                }
              }
              .buttons {
                width: 18vw;
                height: 8vw;
                p {
                  font-size: 0.9rem;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 300px) {
    .header {
      .title {
        p {
          font-size: 1.1rem;
        }
      }
    }
    .container {
      .listContainer {
        .card {
          padding: 1.1rem;
          .imageContainer {
            .profilePicture {
              width: 10vw;
              height: 10vw;
            }
          }
          .textContainer {
            .username {
              p {
                font-size: 1.13rem;
              }
            }
            .buttons {
              p {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}
