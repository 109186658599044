.rootExercises {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  gap: 10px;
  border-radius: 30px;
  border: 4px solid #d6e1fe;
  .heading {
    height: 4vh;
    width: 100%;
    font-size: 1.5rem;
    margin-top: 20px;
    font-weight: 700;
    color: #0d30ac;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .cardsContainer {
    width: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    .exercises {
      // margin: 1rem;
      overflow: hidden;
      width: 11vw;
      height: 28vh;
      border: 4px solid white;
      border-radius: 20px;
      box-shadow: 0px 2px 8px 3px lightgray;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .calories {
        position: absolute;
        width: 3.5vw;
        height: 3.2vh;
        background: white;
        border-radius: 30px;
        top: 4%;
        left: 4%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .reacticon {
          font-size: 18px;
        }

        .count {
          margin-left: 0.1vw;
          height: auto;
          // background-color: red;
          span {
            font-size: 1.1rem;
            font-weight: 700;
            color: #0d30ac;
          }
        }
      }

      .title {
        position: absolute;
        min-width: 80%;
        height: auto;
        background: #f5f1ff;
        border-radius: 30px;
        bottom: 6%;
        left: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 13px;
        padding-top: 3%;
        padding-bottom: 3%;
        span {
          text-align: center;
          color: #0d30ac;
          font-size: 0.66rem;
          font-weight: 600;
        }
      }
    }
  }

  .button {
    margin-top: 5%;
    // width: 20%;
    padding: 6px 20px;
    // height: 50px;
    background: #3e61dc;
    cursor: pointer;
    border-radius: 10px;

    .mainbutton {
      width: 100%;
      height: 100%;
      // margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      span {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    padding: 0;
    border-radius: 18px;
    margin-top: 3%;
    .heading {
      font-size: 1.8rem;
    }
    .cardsContainer {
      flex-direction: row;
      justify-content: space-evenly;
      .exercises {
        width: 22vw;
        height: 20vh;
        border: 3px solid white;
        box-shadow: 0px 1px 6px 2px lightgray;
        .calories {
          width: 8vw;
          height: 2.4vh;
          .icon {
            height: 2vh;
            .reacticon {
              font-size: 18px;
            }
          }
          .count {
            height: 100%;
            display: flex;
            align-items: center;
            span {
              font-size: 1.2rem;
            }
          }
        }
        .title {
          width: 90%;
          left: 5%;
          bottom: 4%;
          background: #f5f1ff;
          border-radius: 10px;
          span {
            padding: 4%;
            font-size: 0.9rem;
          }
        }
      }
    }
    .button {
      margin: 2.5% 0;
      padding: 1.5% 4%;
      .mainbutton {
        width: 100%;
        height: 100%;
        span {
          font-size: 1.2rem;
        }
      }
    }
  }
  @media only screen and (max-width: 820px) {
    .cardsContainer {
      .exercises {
        width: 22vw;
        .calories {
          width: 8vw;
          height: 2.4vh;
          .icon {
            height: 2vh;
            .reacticon {
              font-size: 18px;
            }
          }
          .count {
            span {
              font-size: 1.1rem;
            }
          }
        }
        .title {
          span {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    padding: 0;
    border: 2px solid #d6e1fe;
    border-radius: 15px;
    margin-top: 5%;
    .heading {
      height: 1rem;
      font-size: 1.1rem;
    }
    .cardsContainer {
      justify-content: space-evenly;
      gap: 0;
      .exercises {
        width: 28vw;
        .calories {
          width: 14vw;
          height: 2.4vh;
          .icon {
            height: 2vh;
            .reacticon {
              font-size: 18px;
            }
          }
          .count {
            height: 3vh;
            span {
              font-size: 1rem;
            }
          }
        }
        .title {
          bottom: 2%;
          span {
            font-size: 0.7rem;
          }
        }
      }
    }
    .button {
      margin: 2.5% 0;
      .mainbutton {
        width: 100%;
        height: 100%;
        span {
          font-size: 13px;
        }
      }
    }
  }
}
