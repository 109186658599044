.rootDiv {
  background-color: black;
  padding-bottom: 3%;
  .header {
    margin-top: 3%;
    // margin-bottom: 2%;
    border-bottom: 0.3px solid rgb(96, 96, 96);
    .headerContainer {
      width: 82vw;
      margin: auto;
      margin-bottom: 1%;
      .content {
        display: flex;
        img {
          width: 30vw;
        }
        .texts {
          margin-top: 8%;
          width: 30vw;
          .text {
            font-size: 2.2rem;
            font-weight: 500;
            color: white;
            margin-bottom: 5%;
          }
          button {
            border: 1px solid white;
            color: white;
            margin-bottom: 7%;
            &:hover {
              background: rgb(184, 255, 4);
              color: black;
              border: none;
            }
          }
          .text2 {
            font-size: 1rem;
            color: gray;
          }
          span {
            color: rgb(184, 255, 4);
            text-decoration: underline;
          }
        }
      }
      .description {
        color: rgb(184, 255, 4);
        font-weight: 600;
        font-size: 1.7rem;
        text-align: center;
      }
    }
  }
  .intro {
    .content {
      width: 75vw;
      margin: 5% auto;
      img {
        width: 37vw;
      }
      .texts {
        width: 34vw;
        .label {
          color: rgb(184, 255, 4);
          font-size: 1.2rem;
          font-weight: 700;
          margin-bottom: 3%;
        }
        .text {
          font-size: 1.1rem;
          color: rgb(161, 161, 161);
          margin-bottom: 10%;
          line-height: 34px;
        }
        button {
          border: 1px solid white;
          color: white;
          margin-bottom: 7%;
          &:hover {
            background: rgb(184, 255, 4);
            color: black;
            border: none;
          }
        }
      }
    }
  }
  .cardComponent {
    width: 76vw;
    margin: auto;
    .cardHeader {
      color: rgb(184, 255, 4);
      font-size: 2.2rem;
      font-weight: 700;
      text-align: center;
    }
    .cards {
      justify-content: space-between;
      .cardItem {
        width: 17vw;
        margin: 6% auto;
        border-radius: 20px;
        background-color: rgb(37, 37, 37);
        padding: 3.3%;
        img {
          width: 5vw;
        }
        .label {
          color: white;
          font-size: 1.3rem;
          font-weight: 800;
          margin: 10% auto;
        }
        .text {
          color: rgb(169, 169, 169);
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 5%;
          line-height: 28px;
        }
      }
    }
  }
  .cardComponent2 {
    .cards {
      .cardItem {
        width: 14vw;
        margin: 0 auto 6% auto;
      }
    }
  }
  .banner {
    width: 78vw;
    margin: 5% auto;
    padding: 5%;
    border-radius: 24px;
    background-color: rgb(184, 255, 4);
    color: black;
    .bannerHeader {
      font-size: 3rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 3%;
    }
    .bannerText {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 40px;
    }
  }
  .banner2 {
    display: flex;
    .bannerContainer {
      width: 50vw;
      .bannerHeader {
        text-align: left;
        font-weight: 800;
      }
    }
    .bannerButton {
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      button {
        cursor: pointer;
        color: rgb(184, 255, 4);
        background-color: black;
        &:hover {
          background-color: white;
          color: black;
        }
      }
    }
  }
  .followContainer {
    padding: auto 2%;
    width: 78vw;
    margin: auto;
    .first {
      flex: 1;
      button {
        border-radius: 5px;
        background: rgb(81, 113, 211);
        color: white;
        &:hover {
          background: rgba(81, 113, 211, 0.92);
        }
      }
      .firstLabel {
        color: rgb(184, 255, 4);
        font-size: 2.8rem;
        font-weight: 800;
        margin-bottom: 3%;
      }
      .firstText {
        margin-bottom: 6%;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 30px;
        color: rgb(143, 143, 143);
      }
      .firstIcons {
        justify-content: space-around;
        align-items: center;
        .icon {
          font-size: 3rem;
          color: rgb(184, 255, 4);
        }
      }
    }
  }
  .formContainer {
    width: 78vw;
    margin: 5% auto;
    padding: 5%;
    border-radius: 24px;
    background-color: rgb(184, 255, 4);
    color: black;
    display: flex;
    .form {
      width: 40vw;
      .formHeader {
        font-size: 2.8rem;
        margin-bottom: 2%;
      }
      form {
        margin: 3% auto;
        label {
          font-weight: 500;
        }
        input,
        textarea {
          width: 100%;
          margin-bottom: 3%;
          background-color: white;
          border-radius: 8px;
          border-width: 1px;
          padding: 3%;
          &:hover {
            box-shadow: 0px 0px 0.5px 0.5px rgb(56, 137, 230) !important;
          }
        }
        input {
          height: 7vh;
        }
        textarea {
          height: 28vh;
        }
        button {
          width: 10vw;
          height: 6vh;
          border-radius: 12px;
          background-color: rgb(58, 82, 221);
          color: white;
          &:hover {
            background-color: black;
          }
        }
        p {
          line-height: 35px;
          font-weight: 500;
          font-size: 1.2rem;
          margin-top: 3%;
        }
      }
    }
    .formButton {
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      button {
        cursor: pointer;
        color: rgb(184, 255, 4);
        background-color: black;
        &:hover {
          background-color: white;
          color: black;
        }
      }
    }
  }
  .bottomContainer {
    width: 78vw;
    margin: auto;
    display: flex;
    justify-content: space-around;
    position: relative;
    .bottomTexts {
      width: 26vw;
      flex-direction: column;
      justify-content: center;
      .bottomHeader {
        color: white;
        margin-bottom: 4%;
      }
      .bottomText {
        line-height: 35px;
        color: rgb(169, 167, 167);
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
    img {
      width: 40vw;
      border-radius: 50%;
      -webkit-mask-image: radial-gradient(
        circle at center,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 80%
      );
      mask-image: radial-gradient(
        circle at center,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 80%
      );
    }
  }
  .footer {
    color: white;
    width: 80vw;
    margin: auto;

    div {
      margin-top: 3%;
      p {
        margin-top: 2%;
        font-size: 1.3rem;
      }
      .headers {
        font-size: 1.5rem;
        font-weight: 700;
        border-left: 4px solid rgb(25, 62, 212);
        padding-left: 2%;
      }
      .smallText {
        font-size: 1.1rem;
      }
      .inputContainer {
        background-color: white;
        border-radius: 10px;
        height: 3.5vw;
        input {
          padding: 2%;
          outline: none;
          color: black;
          border: none;
          box-shadow: none;
          font-weight: 600;
          height: 100%;
        }
        button {
          height: 100%;
          width: 30vw;
          padding: 2% 2%;
          font-weight: 600;
          background-color: rgb(73, 70, 198);
          color: white;
          border-radius: 10px;
        }
      }
    }
    .last {
      margin-top: 3%;
      // justify-content: space-between;
      p {
        text-align: center;
      }
      .copy {
        color: rgb(176, 176, 176);
        font-size: 1.1rem;
        margin-top: 0.5%;
      }
    }
  }
  button {
    width: 12vw;
    height: 7vh;
    font-weight: 700;
    border-radius: 30px;
    background: transparent;
  }
}
@media only screen and (max-width: 1024px) {
  .rootDiv {
    button {
      width: 18vw;
      height: 4vh;
      font-weight: 700;
      border-radius: 30px;
      background: transparent;
    }
    .formContainer {
      .form {
        width: 45vw;
        form {
          input {
            height: 5vh;
          }
          textarea {
            height: 20vh;
          }
          button {
            width: 18vw;
            height: 4vh;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .rootDiv {
    padding-bottom: 5%;
    border-bottom: 1px solid gray;
    button {
      width: 30vw;
      height: 5vh;
    }
    .header {
      .headerContainer {
        width: 100vw;
        margin-bottom: 1%;
        .content {
          .texts {
            margin-top: 18%;
            width: 100%;
            text-align: center;
            .text {
              font-size: 3rem;
              padding: 0 5%;
              margin-bottom: 10%;
            }
            button {
              margin-bottom: 5%;
            }
            .text2 {
              font-size: 1.2rem;
              color: gray;
            }
            span {
              color: rgb(184, 255, 4);
              text-decoration: underline;
            }
          }
        }
        .description {
          padding: 0 8%;
          margin-top: 10%;
          margin-bottom: 5%;
        }
      }
    }
    .intro {
      .content {
        width: 100vw;
        flex-direction: column;
        margin: 10% auto;
        img {
          width: 80vw;
        }
        .texts {
          width: 80%;
          margin: auto;
          text-align: center;
          .label {
            font-size: 1.4rem;
            margin-bottom: 3%;
            margin-top: 6%;
          }
          .text {
            font-size: 1.2rem;
            margin-bottom: 10%;
            line-height: 40px;
          }
        }
      }
    }
    .cardComponent {
      width: 80vw;
      .cardHeader {
        font-size: 2.5rem;
      }
      .cards {
        margin: 10% auto;
        flex-direction: column;
        justify-content: space-between;
        .cardItem {
          width: 100%;
          margin-bottom: 4%;
          padding: 6%;
          img {
            width: 12vw;
          }
          .label {
            font-size: 1.5rem;
            margin: 4% auto;
          }
          .text {
            font-size: 1.2rem;
          }
        }
        .cardItem:last-child {
          margin-bottom: 0;
        }
      }
    }
    .cardComponent2 {
      .cards {
        .cardItem {
          width: 100%;
          margin: 0 auto 8% auto;
        }
        .cardItem:last-child {
          margin: 0;
        }
      }
    }
    .banner {
      width: 80vw;
      margin: 12% auto;
      padding: 8%;
      .bannerHeader {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        margin-bottom: 8%;
      }
      .bannerText {
        text-align: center;
        font-size: 1.2rem;
        line-height: 30px;
      }
    }
    .banner2 {
      flex-direction: column;
      .bannerContainer {
        width: 100%;
        .bannerHeader {
          text-align: center;
        }
      }
      .bannerButton {
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        button {
          margin-top: 10%;
          font-size: 1.2rem;
        }
      }
    }
    .followContainer {
      width: 70vw;
      flex-direction: column;
      .first {
        flex: 1;
        margin-bottom: 5%;
        .firstLabel {
          font-size: 2.5rem;
          margin-bottom: 6%;
          line-height: 40px;
        }
        .firstText {
          font-size: 1.3rem;
          margin-bottom: 8%;
          line-height: 35px;
        }
        .firstIcons {
          flex-direction: column;
          align-items: center;
          .icon {
            margin-bottom: 8%;
          }
        }
      }
      .first:last-child {
        margin: 0;
      }
    }
    .formContainer {
      width: 80vw;
      margin: 6% auto;
      padding: 5%;
      flex-direction: column;
      .form {
        width: 100%;
        .formHeader {
          font-size: 2.5rem;
          text-align: center;
          margin-bottom: 8%;
          line-height: 40px;
        }
        form {
          font-size: 1.3rem;
          label {
            font-weight: 500;
          }
          input,
          textarea {
            width: 100%;
            margin-bottom: 6%;
            padding: 5%;
          }
          button {
            width: 30vw;
            height: 5vh;
            margin: 3% auto;
          }
          p {
            text-align: center;
          }
        }
      }
      .formButton {
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        button {
          margin-top: 5%;
          font-size: 1.2rem;
        }
      }
    }
    .bottomContainer {
      width: 80vw;
      flex-direction: column-reverse;
      margin-bottom: 14%;
      .bottomTexts {
        width: 95%;
        margin: auto;
        text-align: center;
        .bottomHeader {
          margin-bottom: 8%;
        }
        .bottomText {
          font-size: 1.3rem;
        }
      }
      img {
        width: 90%;
        margin: auto;
      }
    }
    .footer {
      color: white;
      width: 75vw;
      margin: auto;

      div {
        margin-top: 5%;
        p {
          margin-top: 3%;
          font-size: 1.3rem;
        }
        .headers {
          font-size: 1.5rem;
          font-weight: 700;
          border-left: 4px solid rgb(25, 62, 212);
          padding-left: 5%;
        }
        .smallText {
          font-size: 1.1rem;
        }
        .inputContainer {
          background-color: white;
          border-radius: 8px;
          height: 5vw;
          input {
            padding: 3%;
            height: 100%;
            outline: none;
            border: none;
            box-shadow: none;
            font-weight: 700;
          }
          button {
            width: 50vw;
            // padding: 4% 7%;
            font-weight: 600;
            background-color: rgb(73, 70, 198);
            color: white;
            border-radius: 8px;
          }
        }
      }
      .last {
        margin-top: 10%;
        p {
          text-align: center;
        }
        .copy {
          color: rgb(176, 176, 176);
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .rootDiv {
    button {
      width: 48vw;
      height: 6vh;
      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .header {
      .headerContainer {
        width: 95vw;
        margin: auto;
        .content {
          .texts {
            .text {
              font-size: 2rem;
            }
            button {
              margin-bottom: 5%;
            }
            .text2 {
              font-size: 1rem;
            }
          }
        }
        .description {
          margin-top: 15%;
          font-size: 1.5rem;
          margin-bottom: 3%;
        }
      }
    }
    .intro {
      .content {
        margin: 15% auto;
        .texts {
          .label {
            font-size: 1.2rem;
          }
          .text {
            font-size: 0.9rem;
            line-height: 30px;
          }
          button {
            margin-bottom: 5%;
          }
        }
      }
    }
    .cardComponent {
      width: 90vw;
      .cardHeader {
        font-size: 2rem;
      }
      .cards {
        .cardItem {
          padding: 8%;
          img {
            width: 15vw;
          }
          .label {
            font-size: 1.2rem;
            margin: 6% auto;
          }
          .text {
            font-size: 0.9rem;
          }
        }
      }
    }
    .banner {
      width: 90vw;
      margin: 16% auto;
      padding: 9%;
      .bannerHeader {
        font-size: 2rem;
        margin-bottom: 10%;
      }
      .bannerText {
        font-size: 1rem;
      }
    }
    .banner2 {
      .bannerButton {
        button {
          margin-top: 15%;
        }
      }
    }
    .followContainer {
      width: 80vw;
      .first {
        .firstLabel {
          font-size: 1.8rem;
        }
        .firstText {
          font-size: 1rem;
          line-height: 30px;
        }
      }
    }
    .formContainer {
      width: 90vw;
      padding: 7%;
      .form {
        .formHeader {
          font-size: 2.2rem;
        }
        form {
          margin: 3% auto;
          label {
            font-size: 1.1rem;
          }
          p {
            font-size: 1rem;
            line-height: 30px;
          }
        }
      }
      .formButton {
        button {
          margin-top: 8%;
        }
      }
    }
    .bottomContainer {
      width: 90vw;
      img {
        width: 100%;
        margin-top: 10%;
      }
      .bottomTexts {
        .bottomText {
          font-size: 1rem;
        }
      }
    }
    .footer {
      width: 80vw;
      div {
        margin-top: 4%;
        p {
          margin-top: 3%;
          font-size: 1rem;
        }
        .headers {
          font-size: 1.2rem;
        }
        .smallText {
          font-size: 0.8rem;
        }
        .inputContainer {
          height: 8vw;
          input {
            font-size: 0.9rem;
          }
          button {
            font-size: 0.9rem;
          }
        }
      }
      .last {
        margin-top: 5%;
        .copy {
          font-size: 0.8rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .rootDiv {
    button {
      width: 40vw;
      height: 5.5vh;
      font-size: 0.9rem;
    }
    .formContainer {
      .formButton {
        button {
          font-size: 0.8rem;
        }
      }
    }
    .header {
      .headerContainer {
        width: 95vw;
        margin: auto;
        .content {
          .texts {
            .text {
              font-size: 1.6rem;
            }
            .text2 {
              font-size: 0.8rem;
            }
          }
        }
        .description {
          font-size: 1.3rem;
        }
      }
    }
    .banner2 {
      .bannerButton {
        button {
          font-size: 0.8rem;
        }
      }
    }
    .intro {
      .content {
        .texts {
          .label {
            font-size: 1.1rem;
          }
          .text {
            font-size: 0.8rem;
            line-height: 25px;
          }
        }
      }
    }
    .cardComponent {
      .cardHeader {
        font-size: 1.7rem;
      }
      .cards {
        .cardItem {
          img {
            width: 15vw;
          }
          .label {
            font-size: 1rem;
          }
          .text {
            font-size: 0.8rem;
          }
        }
      }
    }
    .banner {
      .bannerHeader {
        font-size: 1.7rem;
      }
      .bannerText {
        font-size: 0.9rem;
      }
    }

    .followContainer {
      .first {
        .firstLabel {
          font-size: 1.5rem;
        }
        .firstText {
          font-size: 0.8rem;
          line-height: 25px;
        }
      }
    }
    .formContainer {
      .form {
        .formHeader {
          font-size: 2rem;
        }
        form {
          label {
            font-size: 1rem;
          }
          p {
            font-size: 0.8rem;
            line-height: 25px;
          }
        }
      }
    }
    .bottomContainer {
      .bottomTexts {
        .bottomText {
          font-size: 0.8rem;
        }
      }
    }
    .footer {
      div {
        p {
          font-size: 0.8rem;
        }
        .headers {
          font-size: 1rem;
        }
        .smallText {
          font-size: 0.7rem;
        }
        .inputContainer {
          height: 10vw;
        }
      }
      .last {
        .copy {
          font-size: 0.7rem;
        }
      }
    }
  }
}
