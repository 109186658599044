.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;

  div {
    display: flex;
    font-weight: 500;
    align-items: center;
    gap: 1px;
    color: blue;
    // width: 10vw;
  }
  p {
    font-weight: 500;
    font-size: 1rem;
  }
}

.center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  div {
    flex: 1;
  }
  .percentage {
    flex: 2;
    div {
      justify-content: center;
    }
    span {
      font-size: 1rem;
      color: rgb(50, 255, 47);
      text-align: center;
      display: flex;
      align-items: center;
    }
    p {
      text-align: center;
    }
  }

  h1 {
    flex: 2;
    font-size: 2rem;
    text-align: center;
  }
  .downarrow {
    transform: rotate(180deg);
  }
}
@media only screen and (max-width: 1024px) {
  .top {
    div {
      select {
        font-size: 0.9rem;
        height: 2.4vh;
        border-radius: 30px;
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .top {
    div {
      select {
        height: 3vh;
      }
    }
  }
  .center {
    .percentage {
      span {
        font-size: 0.9rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .top {
    margin-top: 10px;
    p {
      font-size: 1.1rem;
      font-weight: 600;
    }
    div {
      font-size: 16px;
    }
  }
  .center {
    padding-bottom: 2%;
    color: white;

    div {
      gap: 0;
      span {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
