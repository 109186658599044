.rootExercises {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    margin-bottom: 2%;
    color: #0d1dac;
    .title {
      font-size: 2rem !important;
      font-weight: 600;
      letter-spacing: 0em;
    }
  }
  .outerContainer {
    min-height: 80vh;
    padding: 1% 3%;
    border-radius: 14px;
    border: 3px solid #4370cb3c;
    .searchBarNotificationContainer {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 2% auto;
      .searchBarContainer {
        flex-grow: 1;
        display: flex;
        align-items: center;
        border: 1px solid #0d30ac;
        border-radius: 10px;
        height: 2.5vw;
        padding: 2px 5px;
        .searchIconContainer {
          border-radius: 50%;
          padding: 5px;
        }

        .searchInput {
          font-size: 1rem;
          width: 100%;
          box-shadow: none;
          border: 0;
          outline: 0;
          padding: 5px;
          margin-left: 5px;
        }
      }

      .buttons {
        margin-left: 1%;
        // width: 28%;
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
        // justify-content: space-around;
        .backButton {
          color: #0d30ac;
          height: 2.5vw;
          border-color: #3855b7;
          border-radius: 8px;
        }
        .addExercise {
          height: 2.5vw;
          width: 5vw;
          border: 1px solid #0d30ac;
          font-size: 1.2em;
          font-weight: 600;
          color: #0d30ac;
          border-radius: 10px;
          &:hover {
            cursor: pointer;
          }
        }

        .toggle {
          height: 2.5vw;
          width: 2vw;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            height: inherit;
            width: 100%;

            &:hover {
              cursor: pointer;
            }
          }
        }
        .notificationIconContainer {
          border-radius: 8px;
          position: relative;
          font-size: 1.2em;
          font-weight: 500;
          color: #e7f3ff;
          background-color: #3855b7;
          width: 5vw;
          height: 5vh;
          cursor: pointer;
          .dropDown {
            padding: 5% 2%;
            width: 15vw;
            height: auto;
            border: 3px solid #3856b784;
            border-radius: 5px;
            background-color: white;
            box-shadow: 0px 1px 3px 1px lightgray;
            position: absolute;
            left: -320%;
            top: 50%;
            color: #0d30ac;
            text-align: center;
            z-index: 5;
            hr {
              border-width: 1px;
              width: 100%;
            }
            .dropDownContent {
              padding: 1% 3%;
              width: 100%;
              input {
                margin: 2% 4%;
                width: 1vw;
                cursor: pointer;
              }
            }
            .button {
              height: 4vh;
              margin-bottom: 5%;
              border: 1px solid #0d30ac;
              color: #0d30ac;
            }
          }
          .dropDownContent input :checked {
            background-color: #0d30ac;
          }
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 1%;
      .arrow {
        margin-bottom: 2%;
        .pagination {
          width: 14vw;
          margin: auto;
          display: flex;
          justify-content: space-evenly;
          button {
            width: 3vw;
            height: 3vw;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        button:hover {
          background-color: rgba(211, 211, 211, 0.372);
          border-radius: 50%;
        }
      }
      .listContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        .card {
          padding: 20px;
          width: 49%;
          margin-bottom: 1.6em;
        }

        .card {
          background-color: #edf0f3;
          display: flex;
          flex-direction: row;
          border-radius: 1vw;
          gap: 20px;

          .imageContainer {
            width: 7vw;
            height: 16vh;
            border-radius: 0.5em;
            border: 1px solid #879fd13c;
          }

          .textContainer {
            width: 80%;
            display: flex;
            flex-direction: column;
            margin: 2% 0;
            .titleBar {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              height: 2vw;
              position: relative;
              h3 {
                display: block;
                font-weight: 600;
                font-size: 1.25em;
                color: var(--secondary-color);
                margin-bottom: 5%;
              }

              .icon {
                cursor: pointer;
                position: absolute;
                top: 0%;
                right: 0%;
                // z-index: ;
              }
            }

            p {
              text-align: justify;
              font-weight: 400;
              font-size: 14px;
              color: #000000;
              margin-top: 1%;
            }
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
      // ........................................................................
      .gridContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 2rem;
        width: 100%;
        .card {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .card {
          background-color: #edf0f3;
          padding: 1em 1.5rem;
          text-align: center;
          border-radius: 1vw;
          width: 19vw;
          min-height: 50vh;
          position: relative;
          .imageContainer {
            border-radius: 5px;
            width: 8vw;
            height: 10vw;
            margin-bottom: 1em;
            border: 1px solid #879fd13c;
          }

          .textContainer {
            display: flex;
            flex-direction: column;
            justify-content: left;
            h3 {
              margin-bottom: 0.5em;
              font-weight: 600;
              font-size: 1.25em;
              color: var(--secondary-color);
            }

            p {
              font-weight: 400;
              font-size: 1em;
              color: #000000;
              text-align: justify;
            }
            .titleBar {
              .icon {
                position: absolute;
                top: 4%;
                right: 4%;
              }
            }
          }
        }
      }
      .gridContainer1 {
        width: 100% !important;

        .card {
          width: 17vw !important;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
@media only screen and (min-height: 1200px) {
  .rootExercises {
    .outerContainer {
      min-height: 90vh;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .rootExercises {
    .outerContainer {
      .searchBarNotificationContainer {
        .searchBarContainer {
          height: 4vh;
          font-size: 1.2rem;
          .searchIconContainer {
            padding: 0 2%;
          }
          .searchInput {
            padding: 0 2%;
          }
        }
        .buttons {
          margin-left: 1%;
          gap: 10px;
          .backButton {
            height: 4vh;
            width: 9vw;
          }
          .addExercise {
            height: 4vh;
            width: 9vw;
            font-size: 1.2em;
          }

          .toggle {
            height: 4vh;
            width: 4vw;
          }
          .notificationIconContainer {
            width: 9vw;
            height: 4vh;
            .dropDown {
              width: 30vw;
              left: -200%;
              top: 100%;
              .dropDownContent {
                font-size: 1rem;
                input {
                  margin: 2% 4%;
                  width: 4vw;
                }
              }
              .button {
                height: 3vh;
                font-size: 1rem;
                margin-bottom: 5%;
              }
            }
          }
        }
      }
      .container {
        flex-direction: column;
        width: 100%;
        margin: auto;
        .arrow {
          .pagination {
            width: 40vw;
            button {
              width: 10vw;
              height: 10vw;
            }
          }
        }
        .listContainer {
          flex-direction: column;
          width: 100%;
          margin-top: 2%;
          .card {
            padding: 12px;
            width: 100%;
          }
          .card {
            gap: 5;
            .imageContainer {
              width: 20vw;
              height: 13vh;
              border-radius: 0.5em;
            }
            .textContainer {
              width: 80%;
              .titleBar {
                height: auto;
                h3 {
                  font-size: 1.4rem;
                  margin-bottom: 1%;
                  line-height: 18px;
                }
              }
              p {
                margin-top: 1%;
                font-size: 1.2rem;
                line-height: 1.8rem;
                text-align: justify;
              }
            }
          }
        }
        // ........................................................................
        .gridContainer1 {
          .card {
            width: 20vw !important;
          }
        }
        .gridContainer {
          width: 100%;
          gap: 20px;
          margin: 2% 0;
          // justify-content: space-between;
          .card {
            width: 26vw;
            min-height: 20vh;
            padding: 14px;
            .imageContainer {
              border-radius: 8px;
              width: 80%;
              height: 16vw;
              margin-bottom: 1em;
            }
            .textContainer {
              display: flex;
              flex-direction: column;
              h3 {
                font-size: 1.3rem;
                margin-bottom: 1%;
              }

              p {
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.8rem;
                text-align: center;
              }
              .titleBar {
                .icon {
                  top: 5%;
                  right: 2%;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .rootExercises {
    width: 100%;
    margin: 0;
    .header {
      font-size: 0.8rem;
      .title {
        font-size: 1.6rem !important;
      }
      .date {
        display: none;
      }
    }
    .outerContainer {
      min-height: 70vh;
      padding: 1% 0;
      .searchBarNotificationContainer {
        flex-direction: column;
        width: 90%;
        margin-bottom: 0;
        .searchBarContainer {
          height: 8vw;
          width: 100%;

          .searchInput {
            font-size: 0.8rem;
          }
          .searchIconContainer {
            svg {
              font-size: 0.9rem;
            }
          }
        }
        .buttons {
          width: 80%;
          margin-top: 3%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .backButton {
            height: 8vw;
            width: 18vw;
          }
          .addExercise {
            height: 8vw;
            width: 18vw;
            font-size: 1em;
          }

          .toggle {
            height: 8vw;
            width: 6vw;
          }
          .notificationIconContainer {
            font-size: 1em;
            width: 18vw;
            height: 8vw;
            .dropDown {
              padding: 5% 2%;
              width: 50vw;
              height: auto;
              left: -280%;
              top: 100%;
              color: #0d30ac;

              text-align: center;
              hr {
                border-width: 1px;
                width: 100%;
              }
              .dropDownContent {
                padding: 1%;
                width: 100%;
                align-items: center;
                font-size: 1rem;
                input {
                  margin: 2% 4%;
                  width: 4vw;
                  cursor: pointer;
                }
              }
              .button {
                height: 3vh;
                font-size: 1rem;
                margin-bottom: 5%;
                border: 1px solid #0d30ac;
                color: #0d30ac;
              }
            }
          }
        }
      }

      .container {
        flex-direction: column-reverse;
        width: 90%;
        margin: auto;
        .arrow {
          .pagination {
            width: 40vw;
            button {
              width: 10vw;
              height: 10vw;
            }
          }
        }
        .listContainer {
          flex-direction: column;
          gap: 10px;
          margin: 2% 0;
          .card {
            gap: 2;
            margin-bottom: 0;
            .imageContainer {
              width: 22vw;
              height: 12vh;
            }
            .textContainer {
              width: 80%;
              .titleBar {
                height: auto;
                h3 {
                  width: 90%;
                  font-size: 0.9rem;
                }
              }
              p {
                font-size: 0.85rem;
                line-height: 1.2rem;
              }
            }
          }
        }
        // ........................................................................
        .gridContainer1 {
          .card {
            width: 40vw !important;
          }
        }
        .gridContainer {
          width: 100%;
          gap: 10px;
          margin: 2% 0;
          .card {
            width: 100%;
            min-height: 20vh;
            padding: 14px;
            .imageContainer {
              width: 40vw;
              height: 24vw;
            }
            .textContainer {
              h3 {
                font-size: 1.1rem;
              }

              p {
                font-size: 0.8rem;
                line-height: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 380px) {
  .rootExercises {
    .header {
      .title {
        font-size: 1.3rem !important;
      }
    }
    .outerContainer {
      .searchBarNotificationContainer {
        .buttons {
          font-size: 0.9rem;
          .backButton {
            font-size: 0.9rem;
          }
          .addExercise {
            font-size: 0.9em;
          }

          .notificationIconContainer {
            font-size: 0.9em;
            .dropDown {
              .button {
                font-size: 0.9rem;
              }
            }
          }
        }
      }

      .container {
        flex-direction: column-reverse;
        width: 90%;
        margin: auto;
        .arrow {
          .pagination {
            width: 40vw;
            button {
              width: 10vw;
              height: 10vw;
            }
          }
        }
      }
    }
  }
}
