.rootDiv {
  background-color: black;
  color: white;
  a {
    color: greenyellow;
    text-decoration: underline;
    cursor: pointer;
  }
  .headingContainer {
    display: flex;
    flex-direction: column;
    padding: 3%;
    justify-content: flex-end;
    background-color: greenyellow;
    color: black;
    .heading {
      font-size: 4rem;
    }
  }
  .container {
    width: 60vw;
    margin: auto;
    padding: 5% 0;
    .header {
      color: greenyellow;
      font-size: 3rem;
      //   text-align: center;
      padding-bottom: 5%;
    }
    .title {
      color: greenyellow;
      padding: 4% 0;
      font-size: 1.5rem;
    }
    .textinfo {
      margin-top: 3%;
    }
    .toc {
      margin-top: 3%;
      a {
        text-decoration: none !important;
      }
    }
    .toc1 {
      a {
        text-decoration: none !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .rootDiv {
    .headingContainer {
      .heading {
        font-size: 3.2rem;
      }
    }
    .container {
      width: 70vw;
      .header {
        font-size: 2rem;
      }
      .title {
        padding: 3% 0;
        font-size: 1.2rem;
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .rootDiv {
    .headingContainer {
      padding: 3%;
      .heading {
        font-size: 2rem;
      }
    }
    .container {
      padding: 4% 0;
      .header {
        font-size: 1.5rem;
        padding-bottom: 4%;
      }
      .title {
        padding: 3% 0;
        font-size: 1.1rem;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .rootDiv {
    .headingContainer {
      padding: 3%;
      .heading {
        font-size: 1.5rem;
      }
    }
    .container {
      width: 80vw;
      padding: 3% 0;
      .header {
        font-size: 1.3rem;
        padding-bottom: 4%;
      }
      .title {
        padding: 4% 0;
        font-size: 1rem;
      }
      .textinfo {
        margin-top: 3%;
        font-size: 0.9rem;
      }
      .table {
        overflow-x: scroll;
      }
      .toc {
        margin-top: 3%;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
}
