.rootClientDetails {
  padding: 3%;
  position: relative;
  .headeing {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    margin-bottom: 2%;
    color: #0d1dac;
    .title {
      font-size: 2rem !important;
      font-weight: 600;
      letter-spacing: 0em;
    }
  }
  .outerContainer {
    min-height: 80vh;
    padding: 1% 3%;
    border-radius: 16px;
    border: 3px solid #4370cb3c;
    .header {
      width: 100%;
      margin: 1% auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .headerContainer {
        display: flex;
        width: max-content;
        gap: 20px;

        .userImage {
          width: 4vw;
          height: 4vw;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            border: 1px solid #0057b389;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .userName {
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            font-weight: 500;
            font-size: 1.8rem;
            color: #0d1dac;
          }
        }
      }

      .headerButtons {
        width: 12vw;
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        font-weight: 500;
        .button {
          padding: 4px 20px;
          border: 1px solid #0d30ac;
          border-radius: 10px;
          color: #0d1dac;
        }
        .buttonChat {
          color: #ffffff;
          padding: 4px 20px;
          background-color: #0d30ac;
          border-radius: 10px;
          border: none;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }

    .dashboard {
      margin: 2% auto;
      width: 100%;
      .healthInfo {
      }

      .bottomContainer {
        margin-top: 3%;
        display: flex;

        .graph {
          .top {
            margin-bottom: 3%;
            p {
              font-size: 1.6rem;
              font-weight: 600;
              color: #0d30ac;
            }
          }

          .chart {
            background: linear-gradient(
              123.33deg,
              #7fa5ed -88.05%,
              #0d30ac 167.83%
            );
            padding: 20px 35px;
            border-radius: 40px;
            width: 40vw;
          }
        }

        .buttons {
          width: 26vw;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          .assign {
            background: linear-gradient(
              123.33deg,
              #7fa5ed -88.05%,
              #0d30ac 167.83%
            );
            padding: 6px 20px;
            margin-bottom: 3%;
            // width: fit-content;
            font-size: 1.2rem;
            border-radius: 30px;
            color: white;
            cursor: pointer;
          }
        }
      }
    }

    /* Overlay Styles */
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: rgba(94, 94, 94, 0.329);
      display: flex;
      align-items: flex-end;
      justify-content: center;
      //   align-items: center;
      z-index: 2;
      .overlayContent {
        background-color: #f4f9ff;
        padding: 30px 50px 0 50px;
        outline: 3px solid #d6e1fe;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 6%;
        .closeicon {
          padding: 2%;
          border-radius: 50%;
          position: relative;
          left: 52%;
          cursor: pointer;
        }
        .closeicon:hover {
          background-color: rgba(211, 211, 211, 0.573);
        }

        .inputBox {
          display: flex;
          flex-direction: column;
          margin: 2px;
          height: 6vw;
          width: 20vw;

          label {
            font-family: "Raleway";
            font-size: 1.4rem;
            font-weight: 500;
            text-align: left;
            padding: 8px 0;
          }

          input {
            background: #ffffff;
            box-shadow: 0px 4px 8px 0px #d3d3d366;
            border-radius: 8px;
            padding: 2% 6%;
            height: 50px;
            width: 100%;
          }
        }

        button {
          margin: 7% 0;
          background-color: #0d30ac;
          box-shadow: 0px 4px 8px 0px #6c9fff40;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 8px;
          cursor: pointer;
          font-size: 1rem;

          &:hover {
            background-color: #0d30acdf; /* Change to a darker shade on hover if desired */
          }
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 0.3rem;
}
@media only screen and (min-height: 1200px) {
  .rootClientDetails {
    .outerContainer {
      min-height: 90vh;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .rootClientDetails {
    .outerContainer {
      .header {
        width: 100%;
        margin-bottom: 3%;
        margin-top: 2%;
        .headerContainer {
          gap: 20px;
          .userImage {
            width: 6vw;
            height: 6vw;
          }
          .userName {
            p {
              font-size: 1.5rem;
              font-weight: 600;
            }
          }
        }
        .headerButtons {
          width: 22vw;
          display: flex;
          justify-content: space-between;
          font-size: 1.2rem;
          .button {
            padding: 1% 12%;
          }
          .buttonChat {
            padding: 1% 12%;
          }
        }
      }

      .dashboard {
        margin: 0;
        width: 100%;
        .healthInfo {
          width: 100%;
          .data {
            height: 100%;
            width: 100%;
            margin: auto 0;
          }
        }

        .bottomContainer {
          margin-top: 10px;
          width: 100%;
          flex-direction: column;
          .graph {
            .top {
              p {
                font-size: 1.5rem;
              }
            }

            .chart {
              width: 100%;
            }
          }

          .buttons {
            margin-top: 5%;
            width: 100%;
            justify-content: space-evenly;
            flex-direction: row;
            flex-wrap: wrap;
            .assign {
              padding: 1% 8%;
              font-size: 1.3rem;
            }
          }
        }
      }
      /* Overlay Styles */
      .overlay {
        align-items: center !important;
        .overlayContent {
          padding: 1%;
          width: 50vw;
          .inputBox {
            margin: 0px;
            height: auto !important;
            width: 100% !important;

            label {
              font-size: 20px;
            }

            input {
              border-radius: 6px;
              height: 40px;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .rootClientDetails {
    .outerContainer {
      .header {
        .headerButtons {
          width: 25vw;
        }
      }
      .overlay {
        .overlayContent {
          width: 60vw;
        }
      }
    }
  }
  /* Overlay Styles */
}
@media only screen and (max-width: 450px) {
  .rootClientDetails {
    .headeing {
      font-size: 0.9rem;
      .title {
        font-size: 1.3rem !important;
      }
    }
    .outerContainer {
      .header {
        .headerContainer {
          gap: 20px;
          .userImage {
            width: 12vw;
            height: 12vw;
          }
          .userName {
            p {
              font-size: 1.3rem;
            }
          }
        }
        .headerButtons {
          width: 36vw;
          font-size: 0.9rem;
          .button {
            padding: 2px 12px;
          }
          .buttonChat {
            padding: 2px 12px;
          }
        }
      }

      .dashboard {
        .bottomContainer {
          margin-top: 10px;
          .graph {
            .top {
              p {
                font-size: 1.1rem;
              }
            }
            .chart {
              padding: 2% 4%;
              border-radius: 20px;
            }
          }

          .buttons {
            margin-top: 5%;
            .assign {
              padding: 5px 14px;
              font-size: 0.9rem;
            }
          }
        }
      }
      .overlay {
        height: 100vh;
        .overlayContent {
          padding: 3%;
          width: 90vw;
          border-radius: 8px;
          outline: 1px solid #d6e1fe;
          .closeicon {
            left: 46%;
          }

          .inputBox {
            margin: 0px;
            width: 100%;

            label {
              font-size: 1.1rem;
            }

            input {
              font-size: 1rem;
              height: 12vw;
              // height: 100%;
            }
          }
          button {
            height: 12vw;
          }
        }
      }
    }
  }
  /* Overlay Styles */
}
