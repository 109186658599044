.rootDiv {
  background-color: black;
  color: white;
  .container {
    padding: 2% 3%;
    .banner {
      position: relative;
      width: 100%;
      height: 15vw;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .bannerImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.7; /* Adjust the opacity as needed */
      }

      .bannerText {
        position: absolute;
        color: white;
        font-size: 2rem;
        font-weight: bold;
      }
    }
    .about {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2% auto;
      gap: 20px;
      width: 80%;
      .aboutText {
        text-align: center;
        color: rgb(214, 214, 214);
      }
      .aboutHeading {
        font-size: 2rem;
        color: greenyellow;
      }
    }
    .mainImage {
      height: 30vw;
      width: 70%;
      object-fit: cover;
    }

    .vision {
      margin: 3% auto;
      .visionContainer {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 5%;
        .visionText {
          width: 30vw;
          margin-top: 3%;
        }
        .visionHead {
          color: greenyellow;
        }
      }
      .visionImage {
        // flex-grow: 1;
        width: 50%;
        .Image {
          border-radius: 10px;
        }
        .visionHead2 {
          color: greenyellow;
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .rootDiv {
    .container {
      padding: 2% 3%;
      .banner {
        height: 18vw;
      }
      .about {
        width: 100%;
      }
      .mainImage {
        height: 30vw;
        width: 60vw;
      }

      .vision {
        flex-direction: column-reverse;
        .visionContainer {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 5%;
          .visionText {
            width: 100%;
            margin-top: 0;
            text-align: center;
          }
          .visionHead {
            color: greenyellow;
            text-align: center;
            display: none;
          }
        }
        .visionImage {
          // flex-grow: 1;
          width: 100%;
          margin: auto;
          flex-direction: column;
          .Image {
            border-radius: 10px;
          }
          .visionHead2 {
            color: greenyellow;
            text-align: center;
            display: block;
            margin-bottom: 3%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .rootDiv {
    .container {
      padding: 2% 3%;
      .banner {
        margin: 4% auto;
        height: 50vw;
        .bannerText {
          font-size: 1.3rem;
        }
      }
      .about {
        width: 100%;
        .aboutHeading {
          font-size: 1.4rem;
        }
        .aboutText {
          font-size: 0.9rem;
        }
      }
      .mainImage {
        height: 30vw;
        width: 60vw;
      }

      .vision {
        flex-direction: column-reverse;
        .visionContainer {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 5%;
          .visionText {
            width: 100%;
            // margin-top: 3%;
            text-align: center;
            font-size: 0.9rem;
          }
          .visionHead {
            color: greenyellow;
            text-align: center;
            font-size: 1.3rem;
          }
        }
        .visionImage {
          // flex-grow: 1;
          width: 100%;
          margin: auto;
          .Image {
            border-radius: 10px;
          }
          .visionHead2 {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
