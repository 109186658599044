.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3%;
  width: 100%;
  height: 100vh;
  & > h1 {
    font-size: 3rem;
    margin-bottom: 30px;
    color: #0d30ac;
  }
}
.wrapper {
  display: flex;
  justify-content: space-between;
}

.left {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #393a62;

  margin-bottom: 25px;
}

.payments {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 45vh;
  overflow-y: auto;
  & > h1 {
    align-self: center;
    color: #484747;
    margin-top: 50px;
  }
}

.payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > p {
    color: #747399;
    font-size: 1.1rem;
    font-weight: 500;
  }
  & > * {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: start;
  }
}
.button {
  background-color: #0d30ac;
  border: none;
  cursor: pointer;
  color: white;
  padding: 15px 35px;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  width: 20vw;
}
@media only screen and (max-width: 1024px) {
  .button {
    padding: 15px 35px;
    font-size: 18px;
    width: 40vw;
  }
}
@media only screen and (max-width: 450px) {
  .container {
    padding: 20px;
    width: 100vw;
    & > h1 {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
  .payments {
    & > h1 {
      font-size: 1rem;
    }
  }
  .left {
    width: 100%;
  }

  .top {
    font-size: 1rem;
  }
  .button {
    border-radius: 5px;
    width: 100%;
    height: 12vw;
    display: flex;
    align-items: center;
  }
}
