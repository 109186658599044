.recheckDelete {
  color: black;
  position: absolute;
  right: 190%;
  top: -10%;
  z-index: 5;
  width: 22vw;
  //   height: 20vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 2px 2px lightgray;
  border-radius: 10px;
  hr {
    border-width: 1.5px;
  }
  h3 {
    padding: 1% 3%;
    margin-top: 2%;
    margin-bottom: 0 !important;
    font-weight: 700;
    text-align: left !important;
  }
  p {
    padding: 1% 3%;
    font-weight: 500 !important;
  }

  .buttonDelete {
    display: flex;
    justify-content: end;
    padding: 3%;

    .bottoncover {
      width: 13vw;
      display: flex;
      justify-content: space-between;
      .cancel {
        border: 2px solid #3856b784;
      }
      .delete {
        background-color: #0d30ac;
        color: white;
      }
    }
    button {
      height: 4.5vh;
      width: 6vw;
      border-radius: 5px;
      border-width: 0px;
      //   color: black;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .recheckDelete {
    width: 40vw;
    .buttonDelete {
      .bottoncover {
        width: 26vw;
        display: flex;
        justify-content: space-between;
      }

      button {
        height: 3vh;
        width: 12vw;
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .recheckDelete {
    width: 45vw;
    p {
      font-size: 1.1rem !important;
    }
  }
}
@media only screen and (max-width: 450px) {
  .recheckDelete {
    width: 60vw;
    // height: 20vh;

    h3 {
      padding: 3%;
      margin-top: 2%;
      font-weight: 600;
    }
    p {
      padding: 3%;
      font-size: 0.9rem !important;
      font-weight: 500;
      text-align: left !important;
    }

    .buttonDelete {
      .bottoncover {
        width: 34vw;
        display: flex;
        justify-content: space-between;
      }

      button {
        height: 3.5vh;
        width: 15vw;
        font-size: 0.9rem;
      }
    }
  }
}
