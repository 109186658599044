.loading-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .loading-container p{
    font-size: 14px;
    font-weight: bold;
    color: white;
}

  .loading-spinner {
    border: 5px solid #c8cbcc;
    border-top: 5px solid rgb(61, 106, 238);
    border-radius: 50%;
    width: 22px;
    height: 22px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }