.assignExercises {
  padding: 2% 3%;
  position: relative;
  .heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    margin-bottom: 1.5%;
    color: #0d1dac;
    .title {
      font-size: 2rem !important;
      font-weight: 600;
      letter-spacing: 0em;
    }
  }
  .header {
    width: 100%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .headerContainer {
      display: flex;
      align-items: center;
      width: max-content;
      gap: 20px;

      .userImage {
        width: 4vw;
        height: 4vw;
        img {
          border: 1px solid #0057b389;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .userName {
        p {
          font-weight: 500;
          font-size: 1.8rem;
          color: #0d1dac;
        }
      }
    }

    .headerButtons {
      width: 9vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.2rem;
      font-weight: 500;
      .back {
        border: 1px solid #0d30ac;
        border-radius: 8px;
        color: #0d1dac;
      }
      .add {
        background-color: #0d30ac;
        color: white;
        border-radius: 50%;
        border: none;
        height: 3vw;
        width: 3vw;
        .icon {
          height: 100%;
          width: 100%;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .outerContainer {
    min-height: 70vh;
    padding: 3%;
    border-radius: 16px;
    border: 3px solid #4370cb3c;
    .buttonsContainer {
      justify-content: space-between;
      .clickedButton {
        background-color: #0d30ac !important;
        color: white !important;
        border: none;
      }
      button {
        background-color: #f0f5ff;
        color: #0d1dac;
        width: 9vw;
        border-bottom: 3px solid #4370cb3c;
      }
      button:hover {
        background-color: #e5edfe;
      }
    }
    .container {
      .head {
        width: 100%;
        margin: 1% 0;
        p {
          font-size: 1.5rem;
          color: #0d1dac;
          font-weight: 600;
        }
      }
      .cards {
        position: relative;
        height: 100%;
        width: 100%;
        .exercise {
          min-height: 2.5vw;
          padding: 1.5%;
          margin-bottom: 1.5%;
          background-color: #f0f5ff;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .exerciseName {
            display: flex;
            align-items: center;
            p {
              font-weight: 600;
              font-size: 1.2rem;
              color: #000000;
            }
          }

          .delete {
            margin-left: 4%;
            position: relative;
            .icon {
              position: absolute;
              top: 0;
              bottom: 0;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  /* Responsive Styles */
  @media only screen and (min-height: 1200px) {
    .outerContainer {
      min-height: 85vh;
    }
  }
  @media only screen and (max-width: 1024px) {
    .heading {
      font-size: 1rem;
      margin-bottom: 2%;
      .title {
        font-size: 1.8rem !important;
      }
    }
    .header {
      margin-bottom: 3%;
      .headerContainer {
        .userImage {
          width: 4vw;
          height: 4vw;
        }
        .userName {
          p {
            font-weight: 500;
            font-size: 1.8rem;
            color: #0d1dac;
          }
        }
      }

      .headerButtons {
        width: 12vw;
        .add {
          height: 4vw;
          width: 4vw;
        }
      }
    }
    .outerContainer {
      padding: 3%;
      .buttonsContainer {
        button {
          width: 9vw;
        }
      }
      .container {
        .head {
          margin: 2% 0;
          p {
            font-size: 1.5rem;
          }
        }
        .cards {
          .exercise {
            min-height: 5vh;
            .exerciseName {
              p {
                font-size: 1.2rem;
              }
            }

            .delete {
              .icon {
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 820px) {
    .heading {
      font-size: 1.1rem;
      margin-bottom: 2%;
      .title {
        font-size: 1.7rem !important;
      }
    }
    .header {
      .headerContainer {
        .userImage {
          width: 5vw;
          height: 5vw;
        }
        .userName {
          p {
            font-size: 1.6rem;
          }
        }
      }

      .headerButtons {
        width: 16vw;
        .add {
          height: 5vw;
          width: 5vw;
        }
      }
    }
    .outerContainer {
      min-height: 80vh;
      .buttonsContainer {
        button {
          width: 10vw;
        }
      }
    }
  }

  @media only screen and (max-width: 450px) {
    .heading {
      font-size: 0.9rem;
      margin-bottom: 2%;
      .title {
        font-size: 1.3rem !important;
      }
    }
    .header {
      .headerContainer {
        .userImage {
          width: 9vw;
          height: 9vw;
        }
        .userName {
          p {
            font-size: 1.3rem;
          }
        }
      }

      .headerButtons {
        width: 28vw;
        .back {
          font-size: 0.9rem;
          height: 10vw;
          width: 15vw;
        }
        .add {
          height: 10vw;
          width: 10vw;
        }
      }
    }
    .outerContainer {
      min-height: 75vh;
      .buttonsContainer {
        width: 100%;
        button {
          padding: 0;
          font-size: 0.8rem;
          height: 7vw;
          width: 3vw;
        }
      }
      .container {
        .head {
          margin: 4% 0;
          p {
            font-size: 1.1rem;
          }
        }
        .cards {
          .exercise {
            margin-bottom: 3%;
            min-height: 5vh;
            .exerciseName {
              p {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .heading {
      font-size: 0.8rem;
      margin-bottom: 2%;
      .title {
        font-size: 1.2rem !important;
      }
    }
  }
}
