.exerciseDetailsContainer {
  width: 100%;
  .header {
    display: flex;
    flex-direction: row;
    width: 100%;
    // margin-top: 2%;
    .exerciseTitle {
      font-weight: 700;
      font-size: 2rem;
      letter-spacing: 0.2px;
      height: 4vw;
      width: 100%;
      p {
        color: #614d8f;
      }
    }

    .buttons {
      border-radius: 10px;
      background-color: #0d30ace1;
      width: 7vw;
      height: 6vh;
      color: #ffffff;
      font-size: 1.2rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .videosContainerMobile {
    display: none;
  }
  .aboutContainerMobile {
    display: none;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    // border: 3px solid #4370cb3c;
    border-radius: 15px;
    padding: 3% 0;
    .left {
      width: 30%;
      .thumbnailContainer {
        // width: 22.5vw;
        // height: 26vw;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .thumbnailImage {
          height: 26vw;
          width: 26vw;
          border-radius: 20px;
          border: 1px solid #4370cb3c;
        }
      }

      .aboutContainer {
        margin-top: 4%;
        display: block;
        .info {
          display: flex;
          flex-direction: row;
          width: 90%;
          height: auto;
          justify-content: space-between;
          .items {
            width: 6vw;
            height: auto;
            background-color: #e7f3ffbf;
            border-radius: 10px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1vw 0.5vw;
            .logo {
              // background-color: red;
              height: 60%;
              width: 100%;

              display: flex;
              align-items: center;
              justify-content: center;
              .icons {
                height: 1.8vw;
                width: auto;
              }
            }
            input {
              border: 1px solid rgba(0, 0, 255, 0.066);
              text-align: center;
              font-size: 14px;
              width: 3vw;
              border-right: 5%;
              background-color: rgba(211, 211, 211, 0.237);
              padding: 0;
              margin-top: 5%;
              &:focus {
                outline: none;
              }
            }
            p {
              margin-top: 0.2vw;
              text-align: center;
              font-weight: 600;
              font-size: 0.9vw;
              color: #614d8f;
              padding: 0 2vw;
              line-height: 15px;
            }
          }
        }
        .aboutTitle {
          height: 3vw;
          p {
            font-weight: 700;
            font-size: 24px;
            color: #614d8f;
          }
        }
      }
    }

    .right {
      //   padding-left: 2vw;
      //   padding-right: 2vw;
      width: 60%;
      .descriptionContainer {
        padding-bottom: 2vw;
        .descriptionTitle {
          width: 100%;
          margin-bottom: 1vw;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          p {
            font-size: 2rem;
            font-weight: 700;
            color: #3b3c40;
          }

          div {
            background-color: #e7f3ff;
            border: 1px solid lightgray;

            border-radius: 50%;
            height: 2vw;
            width: 2vw;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
              height: 60%;
              width: 60%;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }

        .descriptionContent {
          font-weight: 400;
          font-size: 1.4rem;
          text-align: justify;

          .editDesc {
            width: 90%;
            height: 18vh;
            margin-bottom: 5%;

            textarea {
              width: 100%;
              height: 100%;
              resize: none;
              font-weight: 400;
              font-size: 0.8em;
              font-size: 20px;
              margin-bottom: 2%;
            }

            .buttons {
              display: flex;

              .button {
                background-color: #0d30ace2;
                width: 6vw;
                height: 5vh;
                color: #ffffff;
                font-size: 1.1rem;
                font-weight: 500;

                border-radius: 80px;
                margin-right: 2%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .button1 {
                width: 6vw;
                height: 5vh;
                font-size: 1.1rem;
                font-weight: 500;
                border-radius: 80px;
                border: 2px solid #4370cb3c;
              }
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .musclesInvolvedContainer {
        height: 7vw;
        width: 100%;
        margin-top: 4%;
        .musclesInvolvedTitle {
          height: 3vw;
          p {
            font-weight: 700;
            font-size: 1.4rem;
            color: #614d8f;
          }
        }

        .musclesList {
          display: flex;
          flex-direction: row;

          .musclesInvolved {
            // height: 2vw;
            padding: 0.5vw 1.5vw;
            background-color: #e7f3ff;
            margin-right: 1vw;
            border-radius: 5px;

            p {
              font-size: 1vw;
              font-weight: 600;
              color: #614d8f;
            }
          }
        }
      }
      .videosContainer {
        display: block;
        .videosTitle {
          p {
            font-weight: 700;
            font-size: 1.4rem;
            color: #614d8f;
          }
        }

        .videoItem {
          height: 10vw;
          width: 26vw;
          border-radius: 10px;
          display: flex;
          gap: 5px;
          align-items: center;
          justify-content: space-between;
          video {
            width: 14vw;
            height: 18vh;
            border-radius: 10px;
            overflow: hidden;
            object-fit: cover;
          }
          label {
            width: max-content;
          }
        }
      }
    }
  }
}
.label {
  cursor: pointer;
  padding: 6px 15px;
  width: 80%;
  align-self: center;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #614d8f;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(231, 243, 255, 0.15),
    0px 2px 6px 0px rgba(231, 243, 255, 0.25);
  & > span {
    border-radius: 50%;
    background: #e7f3ff;
    display: flex;
    width: 4vw;
    height: 4vw;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  & > label {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .exerciseDetailsContainer {
    width: 95%;
    margin: auto;
    .header {
      margin-bottom: 5%;
      width: 100%;
      height: 4.5vh;
      .exerciseTitle {
        font-size: 2rem;
        height: 100%;
      }

      .buttons {
        border: 2px solid #0d30ac;
        background-color: transparent;
        color: #0d30ac;
        font-weight: 600;
        border-radius: 15px;
        width: 24vw;
        height: 100%;
        font-size: 1.3rem;
      }
    }
    .videosContainerMobile {
      display: block;
      margin-top: 4%;
      width: 100%;
      .videosTitle {
        p {
          font-weight: 700;
          font-size: 1.8rem;
          color: #614d8f;
        }
      }

      .videoItem {
        margin: 2% 0;
        border-radius: 10px;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: space-between;
        video {
          width: 100%;
          height: 24vh;
          border-radius: 10px;
          overflow: hidden;
          object-fit: cover;
        }
        label {
          width: max-content;
        }
      }
    }
    .aboutContainerMobile {
      display: block;
      margin-top: 3%;
      .info {
        margin-top: 3%;
        display: flex;
        flex-direction: row;
        width: 60%;
        min-height: 8vh;
        justify-content: space-between;
        .items {
          width: 12vw;
          height: auto;
          background-color: #e7f3ffbf;
          border-radius: 10px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 1vw 0.5vw;
          .logo {
            // background-color: red;
            height: auto;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2%;
            .icons {
              height: 4vw;
              width: 4vw;
            }
          }
          input {
            border: 1px solid rgba(0, 0, 255, 0.066);
            text-align: center;
            font-size: 14px;
            width: 7vw;
            height: 2vh;
            border-right: 5%;
            background-color: rgba(211, 211, 211, 0.237);
            padding: 0;
            margin-top: 5%;
            &:focus {
              outline: none;
            }
          }
          p {
            margin-top: 0.2vw;
            text-align: center;
            font-weight: 600;
            font-size: 1rem;
            color: #614d8f;
            padding: 0 2vw;
          }
        }
      }
      .aboutTitle {
        height: auto;
        p {
          font-weight: 700;
          font-size: 1.8rem;
          color: #614d8f;
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      .left {
        width: 100%;
        .thumbnailContainer {
          .thumbnailImage {
            height: 50vw;
            width: 100%;
            border-radius: 10px;
          }
        }
        .aboutContainer {
          display: none;
        }
      }

      .right {
        margin: 3% 0;
        width: 100%;
        .descriptionContainer {
          padding-bottom: 2vw;
          .descriptionTitle {
            align-items: center;
            p {
              font-size: 1.8rem;
            }

            div {
              height: 4vw;
              width: 4vw;
            }
          }

          .descriptionContent {
            font-size: 1.2rem !important;
            p {
              line-height: 1.2rem;
              font-weight: 500;
              max-height: 15vh;
              height: auto;
              overflow-y: scroll;
              padding-right: 3%;
            }
            .editDesc {
              width: auto;
              height: auto;
              margin-bottom: 2%;
              textarea {
                width: 100%;
                height: 15vh;
                font-size: 1.2rem;
                padding: 0;
                line-height: 1rem;
                font-weight: 500;
              }
              .buttons {
                display: flex;
                .button {
                  width: 15vw;
                  height: 3vh;
                  color: #ffffff;
                  font-size: 1rem;
                }
                .button1 {
                  width: 15vw;
                  height: 3vh;
                  font-size: 1rem;
                }
              }
            }
          }
        }

        .musclesInvolvedContainer {
          height: auto;
          width: 100%;
          margin-top: 4%;
          .musclesInvolvedTitle {
            height: auto;
            p {
              font-size: 1.8rem;
            }
          }

          .musclesList {
            flex-wrap: wrap;
            .musclesInvolved {
              margin-top: 3%;
              p {
                font-size: 1.2rem;
              }
            }
          }
        }
        .videosContainer {
          display: none;
        }
      }
    }
  }
  .label {
    font-size: 1.2rem;
    padding: 3px 5px;
    width: 100%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #614d8f;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(231, 243, 255, 0.15),
      0px 2px 6px 0px rgba(231, 243, 255, 0.25);
  }
  .label1 {
    height: auto !important;
    & > span {
      border-radius: 50%;
      background: #e7f3ff;
      display: flex;
      width: 8vw;
      height: 8vw;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    & > label {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 450px) {
  .exerciseDetailsContainer {
    .header {
      width: 100%;
      height: 4vh;
      .exerciseTitle {
        font-size: 1.3rem;
      }

      .buttons {
        border-radius: 10px;
        width: 24vw;
        font-size: 1rem;
      }
    }
    .videosContainerMobile {
      .videosTitle {
        p {
          font-size: 1.3rem;
        }
      }
      .videoItem {
        video {
          height: 22vh;
        }
      }
    }
    .aboutContainerMobile {
      .info {
        width: 75%;
        min-height: 8vh;
        .items {
          width: 20vw;
          .logo {
            margin-top: 2%;
            .icons {
              height: 6vw;
              width: 6vw;
            }
          }
          input {
            font-size: 1rem;
            width: 7vw;
          }
        }
      }
      .aboutTitle {
        p {
          font-size: 1.3rem;
        }
      }
    }

    .container {
      .right {
        margin: 3% 0;
        width: 100%;
        .descriptionContainer {
          padding-bottom: 2vw;
          .descriptionTitle {
            p {
              font-size: 1.3rem;
            }

            div {
              height: 6vw;
              width: 6vw;
            }
          }

          .descriptionContent {
            font-size: 1rem !important;

            .editDesc {
              textarea {
                font-size: 1rem;
              }
              .buttons {
                display: flex;
                align-items: center;
                .button {
                  width: 20vw;
                  height: 4vh;
                }
                .button1 {
                  width: 20vw;
                  height: 4vh;
                }
              }
            }
          }
        }

        .musclesInvolvedContainer {
          .musclesInvolvedTitle {
            p {
              font-size: 1.3rem;
            }
          }

          .musclesList {
            .musclesInvolved {
              p {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
  .label {
    font-size: 1rem;
    height: 4vh;
  }
  .label1 {
    height: 3vh;
    & > span {
      width: 8vw;
      height: 8vw;
    }
  }
}
@media only screen and (max-width: 380px) {
  .exerciseDetailsContainer {
    .header {
      .exerciseTitle {
        font-size: 1.2rem;
      }

      .buttons {
        border-radius: 8px;
        width: 22vw;
        height: 8vw;
        border-width: 1px;
        font-size: 0.9rem;
      }
    }
    .videosContainerMobile {
      .videosTitle {
        p {
          font-size: 1.2rem;
        }
      }
    }
    .aboutContainerMobile {
      .info {
        width: 75%;
        min-height: 8vh;
        .items {
          input {
            font-size: 0.9rem;
          }
        }
      }
      .aboutTitle {
        p {
          font-size: 1.2rem;
        }
      }
    }

    .container {
      .right {
        .descriptionContainer {
          .descriptionTitle {
            p {
              font-size: 1.2rem;
            }
          }

          .descriptionContent {
            font-size: 0.9rem !important;

            .editDesc {
              textarea {
                font-size: 0.9rem;
              }
              .buttons {
                gap: 10px;
                .button {
                  font-size: 0.8rem;
                }
                .button1 {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }

        .musclesInvolvedContainer {
          .musclesInvolvedTitle {
            p {
              font-size: 1.2rem;
            }
          }

          .musclesList {
            .musclesInvolved {
              p {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
  .label {
    font-size: 0.9rem;
    height: 4vh;
  }
}
