.button {
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 50%;
  &:hover {
    background-color: #dbe6ff;
  }
  .dot {
    font-size: 1.2rem;
  }
}
.recheckDelete {
  color: black;
  position: absolute;
  right: 200%;
  top: -10%;
  z-index: 10;
  width: 20vw;
  // height: 20vh;
  min-height: 20vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 2px 2px lightgray;
  border-radius: 10px;

  h3 {
    padding: 1% 3%;
    margin-top: 2%;
    margin-bottom: 0 !important;
    font-weight: 700;
    text-align: left !important;
  }
  p {
    padding: 1% 3%;
    font-weight: 500 !important;
  }

  .buttonDelete {
    display: flex;
    justify-content: end;
    padding: 3%;

    .bottoncover {
      display: flex;
      justify-content: space-between;
    }

    .button1 {
      width: 6vw;
      height: clamp(3vh, 4.5vh, 4vh);
      border-radius: 5px;
      border-width: 0px;
      color: black;
      font-weight: 500;
      margin-left: 2%;
      cursor: pointer;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .recheckDelete {
    min-height: auto;
    width: 30vw;
    .buttonDelete {
      .button {
        width: 8vw;
        height: 3vh;
        font-size: 1rem;
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .recheckDelete {
    width: 35vw;
    .buttonDelete {
      .button1 {
        width: 10vw;
        height: 3vh;
        font-size: 1rem;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .button {
    width: 4vw;
    height: 4vw;
  }
  .recheckDelete {
    min-height: 18vh;
    width: 60vw;

    h3 {
      padding: 1% 3%;
      margin-top: 2%;
      font-weight: 600;
      font-size: 1rem;
    }
    p {
      padding: 1% 3%;
      font-weight: 500;
      font-size: 0.9rem;
    }
    .buttonDelete {
      .button1 {
        width: 16vw;
        font-size: 0.9rem;
      }
    }
  }
}
