.rootClientlist {
  height: 100%;
  // max-height: 60vh;
  width: 100%;
  padding: 2px 2%;
  border: 4px solid #d6e1fe;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  .heading {
    display: flex;
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%;
    .head {
      font-size: 25px;
      font-weight: 700;
      color: #0d30ac;
    }
    .button {
      width: 11%;
      padding: 7px;
      background: #3e61dc;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      span {
        font-weight: 500;
        font-size: 0.8rem;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  /* Table Styles */
  .table {
    width: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    table {
      border-collapse: collapse;
      margin-bottom: 20px;
      th,
      td {
        color: #4661be;
        border: 0px;
        padding: 8px;
        width: 10vw;
        text-align: center;

        div {
          display: flex;
          flex-direction: column;
        }
      }
      th {
        font-weight: 600;
        font-size: 20px;
      }
      td {
        font-weight: 500;
      }
    }

    /* Scrollable Container */
    div {
      overflow: auto;
      max-height: 300px; // Adjust the max height as needed
    }

    /* Optional: Styling for Avatar Images */
    td:first-child {
      width: 3vw;
      img {
        width: 2.5vw;
        margin: auto;
        height: 2.5vw;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .table {
      td:first-child {
        img {
          width: 4.2vw;
          height: 4.2vw;
        }
      }
    }
  }
  @media only screen and (max-width: 820px) {
    .table {
      td:first-child {
        img {
          width: 4vw;
          height: 4vw;
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    border: 2px solid #d6e1fe;

    // height: 24vh;
    .heading {
      // width: 84vw;
      margin-top: 10px;
      .head {
        font-size: 20px;
      }
      .button {
        width: 16vw;
        padding: 8px;
        span {
          font-size: 11px;
        }
      }
    }
    .table {
      // width: 96%;
      table {
        th,
        td {
          padding: 6px;
          text-align: left;
          width: 10vw;
        }
        th {
          font-size: 16px;
        }
        td {
          font-size: 12px;
          height: 4vh;
        }
      }
      /* Optional: Styling for Avatar Images */
      td:first-child {
        img {
          width: 6vw;
          height: 3vh;
        }
      }
    }
    @media only screen and (max-width: 400px) {
      .heading {
        .head {
          font-size: 17px;
        }
        .button {
          width: 15vw;
          padding: 6px;
          span {
            font-size: 10px;
          }
        }
      }
      .table {
        table {
          th,
          td {
            padding: 3px;
            width: 9vw;
          }
          th {
            font-size: 14px;
          }
          td {
            font-size: 10px;
            height: 4vh;
          }
        }
        /* Optional: Styling for Avatar Images */
        td:first-child {
          img {
            width: 6vw;
            height: 3vh;
          }
        }
      }
    }
  }
}

.profilePic {
  width: 3rem;
  height: 3rem;

  padding: 6px;

  // background-color: yellow;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
