.rootClients {
  width: 100%;
  height: 100%;
  padding: 2% 3%;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    margin-bottom: 2%;
    color: #0d1dac;
    .title {
      font-size: 2rem !important;
      font-weight: 600;
      letter-spacing: 0em;
    }
  }
  .outerContainer {
    min-height: 80vh;
    padding: 1% 3%;
    border-radius: 14px;
    border: 3px solid #4370cb3c;
    .heading {
      width: 100%;
      margin: 1% auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .headerContainer {
        display: flex;
        align-items: center;
        width: max-content;
        gap: 20px;

        .userImage {
          width: 4vw;
          height: 4vw;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            border: 1px solid #0057b389;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .userName {
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            font-weight: 500;
            font-size: 1.8rem;
            color: #0d1dac;
          }
        }
      }

      .headerButtons {
        width: 12vw;
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        font-weight: 500;
        .button {
          padding: 4px 20px;
          border: 1px solid #0d30ac;
          border-radius: 10px;
          color: #0d1dac;
        }
        .buttonChat {
          color: #ffffff;
          padding: 4px 20px;
          background-color: #0d30ac;
          border-radius: 10px;
          border: none;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .container {
      margin-top: 4%;
      .card {
        margin-top: 2%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 8vh;
        background-color: #edf0f3;
        font-size: 1.2rem;
        font-weight: 500;
        div {
          display: flex;
          gap: 20px;
          p {
            color: #0d1dac;
            // margin-right: 3%;
            text-align: right;
            width: 20vw;
          }
          span {
            width: 20vw;

            text-align: left;
          }
        }
      }
    }
  }
}
@media only screen and (min-height: 1200px) {
  .rootClients {
    .outerContainer {
      min-height: 90vh;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .rootClients {
    .header {
      .title {
        font-size: 1.6rem !important;
      }
    }
    .outerContainer {
      .heading {
        .headerContainer {
          .userImage {
            width: 5vw;
            height: 5vw;
          }
          .userName {
            p {
              font-size: 1.8rem;
            }
          }
        }

        .headerButtons {
          width: 18vw;
        }
      }
      .container {
        .card {
          height: 6vh;
          font-size: 1.4rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .rootClients {
    .header {
      .title {
        font-size: 1.5rem !important;
      }
    }
    .outerContainer {
      .heading {
        .headerContainer {
          .userImage {
            width: 5vw;
            height: 5vw;
          }
          .userName {
            p {
              font-size: 1.6rem;
            }
          }
        }

        .headerButtons {
          width: 23vw;
          .button {
            padding: 2px 18px;
          }
          .buttonChat {
            padding: 2px 18px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .rootClients {
    .header {
      font-size: 0.9rem;
      .title {
        font-size: 1.3rem !important;
      }
    }
    .outerContainer {
      min-height: 80vw;
      .heading {
        .headerContainer {
          gap: 20px;
          .userImage {
            width: 12vw;
            height: 12vw;
          }
          .userName {
            p {
              font-size: 1.3rem;
            }
          }
        }
        .headerButtons {
          width: 36vw;
          font-size: 0.9rem;
          .button {
            padding: 2px 12px;
          }
          .buttonChat {
            padding: 2px 12px;
          }
        }
      }
      .container {
        .card {
          height: 16vw;
          font-size: 1rem;
          margin-bottom: 4%;
          div {
            p {
              width: 40vw;
            }
            span {
              width: 40vw;
            }
          }
        }
      }
    }
  }
}
