.rootWelcomeCard {
  margin-top: 0;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header {
      .date {
        display: none;
      }
      .heading {
        color: #0d30ac;
        font-size: 2rem;
        font-weight: 600;
      }
    }

    .subText {
      font-weight: 500;
      font-size: 16px;
      color: #4371cb;
    }
  }
  @media only screen and (max-width: 820px) {
    .left {
      flex-grow: 1;
      .header {
        display: flex;
        justify-content: space-between;
        .date {
          display: flex;
          align-items: center;
          color: #0d30ac;
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .left {
      .header {
        .heading {
          font-size: 1.3rem;
          font-weight: 600;
        }
        .date {
          display: none;
        }
      }
      .subText {
        font-weight: 500;
        font-size: 0.85rem;
        text-align: justify;
      }
    }
    .right {
      width: 25vw;
      height: 100%;

      img {
        margin-left: 3rem;
        height: 8vh;
      }
    }
    @media only screen and (max-width: 380px) {
      .left {
        .heading {
          font-size: 1.2rem;
        }
        .subText {
          font-size: 10px;
        }
      }
      .right {
        img {
          height: 11vh;
        }
      }
    }
  }
}
