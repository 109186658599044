.rootChats {
  width: 100%;
  height: auto;
  padding: 2% 3%;
  .heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    margin-bottom: 1%;
    color: #0d1dac;
    .title {
      font-size: 2rem !important;
      font-weight: 600;
      letter-spacing: 0em;
    }
  }
  .outerContainer {
    height: 84vh;
    border-radius: 20px;
    border: 3px solid #4370cb3c;
    display: flex;
    flex-direction: column;
    .header {
      width: 100%;
      background-color: #d3d3d340;
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 1%;
      .headerContainer {
        display: flex;
        width: max-content;
        gap: 20px;

        .userImage {
          width: 2.8vw;
          height: 2.8vw;
          img {
            border: 1px solid #4370cb;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .userName {
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            font-weight: 500;
            font-size: 1.5rem;
            color: #0d1dac;
          }
        }
      }

      .headerButtons {
        display: flex;
        justify-content: space-between;
        .buttons {
          padding: 2px 18px;
          border-radius: 10px;
          border: 1px solid #0d30ac;
          color: #0d30ac;
          .button {
            background-color: transparent;
            border: none;
            font-size: 1.1rem;
            font-weight: 500;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .chatMessages {
      flex-grow: 1;
      width: 100%;
      margin: auto;
      overflow-y: scroll;
      padding: 1% 3%;
      //   margin: 2% auto;
      &::-webkit-scrollbar {
        width: 0;
      }

      .message {
        &.received {
          display: flex;
          // max-width: 100%;
          margin-bottom: 15px;
          .img {
            width: 2vw;
            height: 2vw;
            border-radius: 50%;
            margin-right: 2%;
            border: 1px solid #5b8ff7;
          }
          .msg {
            // background: #e4edff;
            padding: 0.5% 0.5% 1.5% 0.5%;
            box-shadow: 0px 0.8px 3px 1px #c8ced8;
            display: flex;
            align-items: center;
            position: relative;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 5px;
            min-height: 5vh;
            flex-direction: column;

            img {
              width: 18vw;
              height: 34vh;
              margin: 1% 0;
              border-radius: 8px;
            }
            p {
              min-width: 6vw !important;
              min-height: 3vh;
              max-width: 30vw;
              text-align: left;
            }
            span {
              position: absolute;
              bottom: 2px;
              right: 8px;
              font-size: 0.7rem !important;
            }
          }
        }

        &.sent {
          display: flex;
          justify-content: flex-end;
          // max-width: 100%;
          margin-bottom: 15px;
          .msg {
            background: #e4edff;
            box-shadow: 0px 0.8px 2px 1px #c9cbce;
            padding: 0.5% 0.5% 1.5% 0.5%;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 5px;
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            min-height: 5vh;
            img {
              width: 18vw;
              height: 34vh;
              margin: 1% 0;
              border-radius: 8px;
            }
            p {
              min-width: 6vw !important;
              min-height: 3vh;
              max-width: 30vw;
              text-align: left;
            }
            span {
              position: absolute;
              bottom: 2px;
              right: 8px;
              font-size: 0.7rem !important;
            }
          }
        }
      }
    }

    .chatInput {
      margin: 0 3% 2% 3%;
      border-radius: 10px;
      box-shadow: 0px 3px 4px 0px #d1d3d8;
      display: flex;
      justify-content: center;
      .inputContainer {
        width: 100%;
        height: 7vh;
        display: flex;
        align-items: center;
        padding: 0 20px;

        input {
          height: 100%;
          width: 100%;
          outline: none;
          box-shadow: none;
          font-size: 18px;
          margin: 0 2%;
        }

        .icon {
          cursor: pointer;
          font-size: 1.8rem;
          font-weight: 500;
          color: rgb(88, 88, 88);
        }
      }
    }
  }
  @media only screen and (min-height: 1200px) {
    .outerContainer {
      height: 90vh;
    }
  }
  @media only screen and (max-width: 1024px) {
    .outerContainer {
      .header {
        height: auto;
        padding: 2%;
        .headerContainer {
          .userImage {
            width: 5vw;
            height: 5vw;
          }
        }

        .headerButtons {
          .buttons {
            padding: 5px 20px;
            .button {
              font-size: 1.2rem;
            }
          }
        }
      }
      .chatMessages {
        flex-grow: 1;
        width: 100%;
        .message {
          &.received {
            margin-bottom: 2%;
            .img {
              width: 4vw;
              height: 4vw;
            }
            .msg {
              padding: 1% 1% 3% 1%;
              font-size: 1rem;
              min-height: 5vh;
              min-width: 10vw;

              img {
                width: 30vw;
                height: 30vw;
              }
              p {
                min-height: 2vh;
                max-width: 30vw;
              }
            }
          }

          &.sent {
            display: flex;
            justify-content: flex-end;
            max-width: 100%;
            margin-bottom: 15px;
            .msg {
              padding: 1% 1% 3% 1%;
              font-size: 1rem;
              min-height: 3vh;
              min-width: 10vw;
              img {
                width: 30vw;
                height: 30vw;
              }
              p {
                min-height: 2vh;
                max-width: 30vw;
              }
            }
          }
        }
      }
      .chatInput {
        .inputContainer {
          height: 5vh;
          input {
            font-size: 1.5rem;
          }

          .icon {
            cursor: pointer;
            font-size: 2rem;
            font-weight: 500;
            color: rgb(88, 88, 88);
          }
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .heading {
      font-size: 0.9rem;
      .title {
        font-size: 1.5rem !important;
        font-weight: 600;
        letter-spacing: 0em;
      }
      .date {
        display: none;
      }
    }
    .outerContainer {
      border: 2px solid #4370cb3c;
      // height: 80vh;
      .header {
        height: 15vw;
        padding: 3%;
        .headerContainer {
          gap: 20px;
          .userImage {
            width: 10vw;
            height: 10vw;
          }
          .userName {
            p {
              font-size: 1.2rem;
            }
          }
        }
        .headerButtons {
          .buttons {
            padding: 5px 16px;
            .button {
              font-size: 0.8rem;
            }
          }
        }
      }
      .chatMessages {
        .message {
          &.received {
            .img {
              width: 8vw;
              height: 8vw;
              margin-right: 2%;
            }
            .msg {
              font-size: 1rem;
              min-height: 3vh;
              padding: 1% 1% 3% 1%;
              p {
                min-height: 5vw;
                max-width: 70vw;
                line-height: 20px;
              }
              span {
                right: 4%;
                bottom: 1%;
                font-size: 0.5rem !important;
              }
              img {
                height: 30vw;
                width: 30vw;
              }
            }
          }

          &.sent {
            .msg {
              min-height: 3vh;
              // max-width: 70%;
              font-size: 1rem;
              padding: 1% 1% 3% 1%;
              p {
                min-height: 5vw;
                max-width: 70vw;
                // max-width: 70%;
                line-height: 20px;
              }
              span {
                right: 4%;
                bottom: 1%;
                font-size: 0.5rem !important;
              }
              img {
                height: 30vw;
                width: 30vw;
              }
            }
          }
        }
      }

      .chatInput {
        margin: 0 4% 3% 4%;
        .inputContainer {
          width: 100%;
          height: 5vh;
          padding: 0 10px;
          input {
            font-size: 1rem;
            padding: 3%;
          }
          .icon {
            font-size: 1.3rem;
            font-weight: 600;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 380px) {
    .heading {
      .title {
        font-size: 1.3rem !important;
      }
    }
    .outerContainer {
      .header {
        .headerContainer {
          gap: 15px;
          .userName {
            p {
              font-size: 1.1rem;
            }
          }
        }
        .headerButtons {
          .buttons {
            padding: 4px 14px;
            .button {
              font-size: 0.7rem;
            }
          }
        }
      }
      .chatMessages {
        .message {
          &.received {
            .msg {
              font-size: 0.9rem;
            }
          }

          &.sent {
            .msg {
              font-size: 0.9rem;
            }
          }
        }
      }

      .chatInput {
        .inputContainer {
          input {
            font-size: 0.9rem;
          }
          .icon {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
