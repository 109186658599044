.rootProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .userPicture {
    overflow: hidden;
    width: 7vw;
    height: 7vw;
    border: 4px solid #0d30ac;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    // margin: auto;
  }
  .name {
    margin-top: 15px;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    color: #0d30ac;
  }
}
@media only screen and (max-width: 1024px) {
  .rootProfile {
    .userPicture {
      border: 4px solid #0d30ac;
      width: 9vw;
      height: 9vw;
    }
  }
}
@media only screen and (max-width: 820px) {
  .rootProfile {
    .userPicture {
      width: 14vw;
      height: 14vw;
      border: 3px solid #0d30ac;
    }
    .name {
      margin-top: 8px;
      font-size: 1.8;
    }
  }
}
@media only screen and (max-width: 450px) {
  .rootProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .name {
      font-size: 1.2rem;
    }
    .userPicture {
      overflow: hidden;
      width: 20vw;
      height: 20vw;
      border: 2px solid #0d30ac;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
