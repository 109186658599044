.rootLogin {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .bgImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/loginbackground.jpeg");
    background-size: cover;
    background-position: center;
    z-index: -1; /* Ensure the background image is behind the overlay component */
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.509);
  }
  .containerMain {
    width: 30vw;
    height: 50vh;
    background-color: #f8f8f8;
    margin: auto;
    position: absolute;
    z-index: 2;
    border-radius: 3%;
    .icon {
      position: relative;
      margin-top: 2%;
      .closeicon {
        width: 3vw;
        height: 3vw;
        padding: 2%;
        border-radius: 50%;
        position: relative;
        left: 87%;
        top: 5%;
        cursor: pointer;
      }
      .closeicon:hover {
        background-color: rgba(211, 211, 211, 0.573);
      }
    }
    .header {
      display: flex;
      justify-content: center;
      height: auto;
      // margin-top: 5%;
      p {
        font-weight: 600;
        font-size: 2.2rem;
        color: #0d30ac;
      }
    }
    .text {
      margin-bottom: 3%;
      font-size: 1.2rem;
      color: #0d30ac;
      font-weight: 500;
    }
    .container {
      width: 80%;
      margin: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 8%;
      .email {
        width: 100%;
        margin-bottom: 40px;
        .label {
          font-weight: 600;
          font-size: 1.2rem;
          color: #614d8f;
          margin-bottom: 15px;
        }

        .input {
          width: 100%;
          height: 6vh;
          background-color: #eeeeee;
          border-radius: 5px;
          display: flex;
          align-items: center;

          .emailIcon {
            width: 1.5vw;
            height: 1.5vw;
            margin: 2% 4%;
            color: #0d30ac;
          }

          input {
            flex-grow: 1;
            height: 100%;
            outline: none;
            border: none;
            background-color: #eeeeee;
            font-size: 1rem;
            font-weight: 500;
            color: #0d30ac;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .signin {
        width: 12vw;
        height: 6vh;
        background-color: #0d30ac;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .confirmText {
      font-size: 1.3rem;
      color: #0d30ac;
      font-weight: 500;
      width: 90%;
      margin: auto;
      text-align: center;
      margin: 10% auto 5% auto;
    }
  }
  .errorpopup {
    width: 20vw;
    height: 10vh;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    border-radius: 5px;
    background-color: rgb(255, 78, 38);
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 6;
  }

  .errorpopup p {
    color: white;
  }
  @media only screen and (max-width: 1024px) {
    .errorpopup {
      width: 30vw;
      height: 7vh;
    }
    .containerMain {
      width: 60vw;
      height: 40vh;
      margin: auto;
      .icon {
        .closeicon {
          width: 7vw;
          height: 7vw;
          padding: 2%;
          left: 87%;
          top: 5%;
        }
        .closeicon:hover {
          background-color: rgba(211, 211, 211, 0.573);
        }
      }
      .header {
        width: 100%;
        height: auto;
        p {
          font-size: 3rem;
        }
      }

      .container {
        width: 80%;
        .email {
          width: 100%;
          margin-bottom: 8%;
          .label {
            font-size: 1.5rem;
            margin-bottom: 10px;
          }
          .input {
            .emailIcon {
              width: 4vw;
              height: 4vw;
            }
            input {
              font-size: 1.5rem;
            }
          }
        }

        .buttons {
          width: 100%;
          height: auto;
          margin-top: 5%;
          .signin {
            width: 100%;
            height: 5vh;
            border-radius: 5px;
            p {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 820px) {
    .errorpopup {
      width: 35vw;
    }
    .containerMain {
      .header {
        p {
          font-size: 2.5rem;
        }
      }
      .container {
        margin-top: 5%;
        .email {
          .label {
            font-size: 1.2rem;
          }
          .input {
            height: 5vh;
            input {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .errorpopup {
      width: 65vw;
    }
    .containerMain {
      width: 90vw;
      height: 40vh;
      .icon {
        .closeicon {
          width: 10vw;
          height: 10vw;
        }
      }
      .header {
        margin: 3% auto 5% auto;
        p {
          font-size: 2rem;
        }
      }

      .container {
        .email {
          width: 80vw;
          margin-bottom: 8%;
          .label {
            font-size: 1rem;
            margin-bottom: 10px;
          }
          .input {
            .emailIcon {
              width: 5vw;
              height: 5vw;
            }
          }
        }

        .buttons {
          width: 50vw;
          height: auto;
          flex-direction: column;
          margin-top: 5%;
          .signin {
            width: 50vw;
            height: 5vh;
            border-radius: 5px;
            p {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .containerMain {
      height: 40vh;
    }
  }
}
