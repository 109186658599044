.rootChats {
  width: 100%;
  height: 100%;
  padding: 2% 3%;
  .container {
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 1.1rem;
      margin-bottom: 2%;
      color: #0d1dac;
      .title {
        font-size: 2rem !important;
        font-weight: 600;
        letter-spacing: 0em;
      }
    }
    .outerContainer {
      min-height: 80vh;
      padding: 1% 3%;
      border-radius: 14px;
      border: 3px solid #4370cb3c;
      .searchBarNotificationContainer {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 2% auto;
        .searchBarContainer {
          flex-grow: 1;
          display: flex;
          align-items: center;
          border: 1px solid #0d30ac;
          border-radius: 10px;
          height: 2.5vw;
          padding: 5px;
          .searchIconContainer {
            border-radius: 50%;
            padding: 5px;
          }

          .searchInput {
            font-size: 1rem;
            width: 100%;
            box-shadow: none;
            border: 0;
            outline: 0;
            padding: 5px;
            margin-left: 5px;
          }
        }

        .notificationIconContainer {
          margin-left: 2%;
          border-radius: 8px;
          background-color: #3855b7;
          width: 5vw;
          height: 5vh;
          position: relative;
          cursor: pointer;
          .dropDown {
            padding: 5% 2%;
            width: 15vw;
            height: auto;
            border: 3px solid #3856b784;
            border-radius: 5px;
            background-color: white;
            box-shadow: 0px 1px 3px 1px lightgray;
            position: absolute;
            left: -320%;
            top: 50%;
            color: #0d30ac;
            text-align: center;
            hr {
              border-width: 1px;
              width: 100%;
            }
            .dropDownContent {
              padding: 1% 3%;
              width: 100%;
              input {
                margin: 2% 4%;
                width: 1vw;
                cursor: pointer;
              }
            }
            .button {
              height: 4vh;
              margin-bottom: 5%;
              border: 1px solid #0d30ac;
              color: #0d30ac;
            }
          }
          .dropDownContent input :checked {
            background-color: #0d30ac;
          }
        }
      }
      .cardsContainer {
        width: 100%;
        background-color: white;
        display: flex;
        // justify-content: space-between;
        gap: 1.3rem;
        flex-wrap: wrap;
        padding-top: 1%;
        .card {
          width: 24.2vw;
          margin-bottom: 0.6%;
          background-color: #edf0f3;
          padding: 1.8rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          // justify-content: center;
          justify-content: space-between;
          border-radius: 1vw;

          .imageContainer {
            width: 15%;
            img {
              border: 1px solid #5b8ff7;
              width: 3vw;
              height: 3vw;
              border-radius: 50%;
              overflow: hidden;
              object-fit: cover;
            }
          }

          .textContainer {
            // background-color: yellow;
            width: 80%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .username {
              p {
                font-weight: 600;
                font-size: 1.53rem;
                color: #0d1dac;
                line-height: 26px;
              }
            }

            .buttons {
              // margin-right: 2vw;
              width: 5vw;
              height: 2vw;
              border-radius: 40px;
              border: 1px solid #361f69;

              display: flex;
              align-items: center;
              justify-content: center;

              background-color: #ffffff;
              font-weight: 600;
              font-size: 1.28rem;
              color: #361f69;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (min-height: 1200px) {
    .container {
      .outerContainer {
        min-height: 90vh;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    .container {
      .outerContainer {
        .searchBarNotificationContainer {
          .searchBarContainer {
            height: 4vh;
            font-size: 1.2rem;
            .searchIconContainer {
              padding: 0 2%;
            }
            .searchInput {
              padding: 0 2%;
            }
          }
          .notificationIconContainer {
            width: 15vw;
            height: 4vh;
            .dropDown {
              padding: 5% 2%;
              width: 30vw;
              height: auto;
              left: -200%;
              top: 100%;
              color: #0d30ac;
              text-align: center;
              hr {
                border-width: 1px;
                width: 100%;
              }
              .dropDownContent {
                padding: 1%;
                width: 100%;
                align-items: center;
                font-size: 1rem;
                input {
                  margin: 2% 4%;
                  width: 4vw;
                  cursor: pointer;
                }
              }
              .button {
                height: 3vh;
                font-size: 1rem;
                margin-bottom: 5%;
                border: 1px solid #0d30ac;
                color: #0d30ac;
              }
            }
          }
        }

        .cardsContainer {
          justify-content: space-between;
          .card {
            width: 35vw;
            .imageContainer {
              .profilePicture {
                width: 4vw;
                height: 4vw;
              }
            }
            .textContainer {
              .username {
                p {
                  line-height: auto;
                }
              }
              .buttons {
                width: 8vw;
                height: 2.4vh;
                p {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 820px) {
    .container {
      width: 100%;
      .outerContainer {
        .cardsContainer {
          .card {
            width: 42vw;
            flex-grow: 1;
          }

          .card {
            padding: 1.2rem;
            border-radius: 10px;
            .imageContainer {
              .profilePicture {
                width: 6vw;
                height: 6vw;
              }
            }

            .textContainer {
              .username {
                p {
                  font-size: 1.43rem;
                }
              }

              .buttons {
                width: 10vw;
                height: 3.2vh;
                p {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .container {
      .header {
        font-size: 0.9rem;
        .title {
          font-size: 1.6rem !important;
          font-weight: 600;
          letter-spacing: 0em;
        }
      }
      .outerContainer {
        flex-grow: 1;
        .searchBarNotificationContainer {
          margin-bottom: 0;
          .searchBarContainer {
            height: 4vh;
            border-radius: 8px;
            .searchInput {
              padding: 0 4px;
            }
            .searchIconContainer {
              svg {
                font-size: 0.9rem;
              }
            }
          }

          .notificationIconContainer {
            width: 15vw;
            height: 4vh;
            font-size: 0.9rem;
            .dropDown {
              padding: 5% 2%;
              width: 50vw;
              height: auto;
              left: -280%;
              top: 100%;
              color: #0d30ac;

              text-align: center;
              hr {
                border-width: 1px;
                width: 100%;
              }
              .dropDownContent {
                padding: 1%;
                width: 100%;
                align-items: center;
                font-size: 1rem;
                input {
                  margin: 2% 4%;
                  width: 4vw;
                  cursor: pointer;
                }
              }
              .button {
                height: 3vh;
                font-size: 1rem;
                margin-bottom: 5%;
                border: 1px solid #0d30ac;
                color: #0d30ac;
              }
            }
          }
        }
        .cardsContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 3%;
          gap: 0;
          .card {
            width: 100%;
            margin-bottom: 3%;
            padding: 1rem 1.2rem;
            justify-content: space-between;
            border-radius: 10px;
            margin-right: 0px;

            .imageContainer {
              width: 10vw;
              .profilePicture {
                width: 10vw;
                height: 10vw;
              }
            }

            .textContainer {
              // background-color: yellow;
              width: 80%;
              .username {
                p {
                  font-size: 1.3rem;
                }
              }

              .buttons {
                margin-right: 2vw;
                width: 16vw;
                height: 8vw;
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 380px) {
    .container {
      .header {
        .title {
          font-size: 1.5rem !important;
        }
      }
      .outerContainer {
        .searchBarNotificationContainer {
          .searchBarContainer {
            border-radius: 6px;
            .searchInput {
              font-size: 0.9rem;
            }
          }

          .notificationIconContainer {
            width: 18vw;
            font-size: 0.8rem;
            .dropDown {
              width: 53vw;
              .dropDownContent {
                font-size: 0.9rem;
              }
              .button {
                font-size: 0.8rem;
              }
            }
          }
        }
        .cardsContainer {
          .card {
            .textContainer {
              .username {
                p {
                  font-size: 1.2rem;
                }
              }
              .buttons {
                height: 6vw;
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}
